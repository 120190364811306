import * as ActionTypes from './index';

export const initDineInPage = (data) => {
    return {
        type: ActionTypes.INIT_DINE_IN_PAGE,
        data,
    };
}

export const initFail = (title) => {
    return {
        type: ActionTypes.INIT_FAIL,
        data: {
            title,
        },
    };
}

export const comfirmOrder = (curOrder) => {
    return {
        type: ActionTypes.CONFIRM_ORDER,
        data: {
            curOrder,
        },
    };
};

export const setFailLoadingWrapper = (status, title) => {
    return {
        type: ActionTypes.SET_FAIL_LOADING_WRAPPER,
        data: {
            status,
            title,
        },
    };
};

export const setLoadingWrapper = (status, title) => {
    return {
        type: ActionTypes.SET_LOADING_WRAPPER,
        data: {
            status,
            title,
        },
    };
};

export const setMsgDialog = (open, msg, isloading) => {
    return {
        type: ActionTypes.SET_MSG_DIALOG,
        data: {
            open,
            msg,
            isloading,
        }
    };
};

export const setAddSpecMealDialog = (open, data) => {
    return {
        type: ActionTypes.SET_ADDS_SPEC_MEAL_DIALOG,
        data: {
            open,
            data,
        },
    };
}

export const setAddMealDialog = (open, data) => {
    return {
        type: ActionTypes.SET_ADD_MEAL_DIALOG,
        data: {
            open,
            data,
        },
    };
}

export const clearCart = () => {
    return {
        type: ActionTypes.CEAR_CART,
    };
};

export const addCartItemSeparately = (data) => {
    return {
        type: ActionTypes.UPDATE_CART_ADD_SEPARATELY,
        data: data
    }
}

export const updateDiscount = (confirmedOrders) =>{
    return (dispatch, getState) => {
        const {menuReducer: {menu: {tagSetList}}} = getState();
        dispatch({
            type: ActionTypes.UPDATE_DISCOUNT,
            data: {tagSetList, confirmedOrders},
        });
    };
}

export const updateProductQty = (qty, productIdx, specIdx) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_QTY,
        data: {
            qty,
            productIdx,
            specIdx,
        },
    };
}

export const parseProductData = () => {
    return {
        type: ActionTypes.PARSE_PRODUCT_DATA,
    };
}
