import React from 'react';
import { Container, Typography, Paper, List, ListItem, ListItemText } from '@material-ui/core';
import CartItemGrid from '../takeout/shoppingCart/CartItemGrid';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import lng from '../../misc/lng';
import DiscountDisplayGrid from '../takeout/shoppingCart/DiscountDisplayGrid';

const { chiLng } = lng;

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // this makes it center vertically
  },
  productData: {
    margin: '10px 0',
    backgroundColor: 'white',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  wrapper: {
    backgroundColor: '#f1efef',
    minHeight: '100vh',
  },
  orderNumber: {
    fontWeight: 'bold',
    backgroundColor: 'white',
  },
  status: {
    backgroundColor: 'white',
  },
  productSummary: {
    margin: '10px 0',
    backgroundColor: 'white',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  totalPriceWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '50px',
    backgroundColor: 'white',
  },
  priceTotalTitle: {
    marginLeft: '10px',
  },
  priceTotal: {
    marginRight: '10px',
  },
}));

const getOrderStatusLng = (status) => {
  switch (status) {
    case 'paymentCompleted':
      return chiLng.orderStatus.PaymentCompleted;
    case 'waitingForPickup':
      return chiLng.orderStatus.WaitingForPickup;
    case 'orderCompleted':
      return chiLng.orderStatus.OrderCompleted;
    case 'sessionExpired':
      return chiLng.orderStatus.SessionExpired;
    default:
      return chiLng.orderStatus.OrderError;
  }
}

const formatOrderNumber = (orderNumber) => {
  return orderNumber.toString().padStart(4, '0');
}

const OrderDetail = ({ orderData }) => {
  const { orderNumber, orders, status, productSummary } = orderData;
  let tagSetDiscountList = [];
  let priceAfterDiscount = null;
  if (productSummary) {
    tagSetDiscountList = productSummary.tagSetDiscountList;
    priceAfterDiscount = productSummary.priceAfterDiscount;
  }
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.orderNumber} variant="h1">{formatOrderNumber(orderNumber)}</Typography>
      <Typography className={classes.status} variant="h6">{getOrderStatusLng(status)}</Typography>

      {orders.map((order, oIdx) => (
        <div key={oIdx}>
          <Typography variant="h6" style={{ marginTop: '10px', backgroundColor: 'white', padding: '10px 0' }}>
            {`${chiLng.orderTime} ${new Date(order.orderTime).toLocaleString()}`}
          </Typography>
          {
            order.orderDetails.map((orderDetail, idx) => {
              return (
                <div
                  className={classes.productData}
                  key={`confirmedOrderCartItemGrid_${oIdx}_${idx}`}
                >
                  <CartItemGrid
                    orderDetail={orderDetail}
                    updateCart={() => { }}
                    selectedItemIdx={0}
                    handleSelectItem={() => { }}
                    idx={idx}
                  />
                </div>
              );
            })
          }
          {
            tagSetDiscountList.length > 0 ?
              (
                <div className={classes.productSummary}>
                  <DiscountDisplayGrid productSummary={productSummary} />
                </div>
              ) : null
          }
          <div className={classes.totalPriceWrapper}>
            <Typography align='left' className={classes.priceTotalTitle} variant='h6'>
              {`${chiLng.shoppingCartPriceTotal}`}
            </Typography>
            <Typography align='right' className={classes.priceTotal} variant='h6'>
              {`$${priceAfterDiscount}`}
            </Typography>
          </div>
        </div>
      ))}
      {/* </Paper> */}
    </div>
  );
};

export default OrderDetail;