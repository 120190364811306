import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import {updateProductQty, parseProductData} from '../../../actions/dineIn';
import lng from '../../../misc/lng';
import { ProjectorScreenOutline } from 'mdi-material-ui';



const {chiLng} = lng;

const useStyles = makeStyles(() => ({
    footer: {
        position: 'fixed',
        left: '0',
        width: '100%',
        height: '130px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0 5px rgba(0, 0, 0, 0.8)',
        transition: 'bottom 0.2s',
        zIndex: '99',

    },
    openCart: {
        bottom: '0',
    },
    closeCart: {
        bottom: '-135px',
    },
    qtyBtnWrapper: {
        margin: '10px auto',
        display: 'flex',
        alignItems: 'center',
    },
    qty: {
        color: '#d2691e',
        fontWeight: '600',
        fontSize: '30px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    addOrderBtn: {
        width: '70%',
        height: '40px',
        color: 'white',
        backgroundColor: '#666666',
        borderRadius: '10px',
        fontSize: '30px',
        lineHeight: '40px',
    },
  }));


export default (props) => {
    const classes = useStyles();
    const {
        productData
    } = useSelector((store) => store.productsReducer);
    const dispatch = useDispatch();
    const [qty, setQty] = useState(0);

    useEffect(
        () => {
            console.log('change idx')
            if (props.open) {
                let _qty = 0;
                productData.forEach(
                    (product, pIdx) => {
                        if (pIdx === props.pIdx) {
                            if (product.hasMenuSpec === 'true') {
                                _qty = product.productSpec[0].qty;
                            } else {
                                _qty = product.qty;
                            }
                        }
                    }
                );
                setQty(_qty);
            }
        }, [props.pIdx, productData, props.open]
    );

    const getCartStyle = () => {
        if (props.open) {
            return classes.openCart;
        }
        return classes.closeCart;
    }

    const handleReduce = () => {
        if (qty >= 1) {
            dispatch(updateProductQty(qty - 1, props.pIdx, 0))
        }
    }

    const handleConfirm = () => {
        dispatch(parseProductData());
        props.handleClose()
    }

    return(
        <div className={`${classes.footer} ${getCartStyle()}`}>
            <div className={classes.qtyBtnWrapper}>
                <div className={classes.qtyAdd}>
                    <IconButton aria-label="add" onClick={() => {dispatch(updateProductQty(qty + 1, props.pIdx, 0))}}>
                        <AddCircleOutlineOutlinedIcon fontSize="large" />
                    </IconButton>
                </div>
                <div className={classes.qty}>
                    {qty}
                </div>
                <div className={classes.qtyReduce}>
                    <IconButton aria-label="reduce" onClick={handleReduce}>
                        <RemoveCircleOutlineOutlinedIcon fontSize="large" />
                    </IconButton>
                </div>
            </div>
            <Button
                variant="contained"
                size="large"
                color="primary"
                classes={{containedPrimary: classes.addOrderBtn}}
                onClick={handleConfirm}
            >
            {`${chiLng.qtyDialogConfirm}`}
            </Button>
        </div>
    );
};