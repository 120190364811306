import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import lng from '../../misc/lng';

const {chiLng} = lng;

const useStyles = makeStyles(() => ({
    loading: {
      display: 'flex',
      width: '100%',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      backgroundColor: 'white',
      zIndex: '9999',
      flexDirection: 'column'
    },
    successIcon: {
      fontSize: '60px',
      color: '#fb7663'
    },
    btn: {
      marginTop: '20px',
    }
  }));


export default (props) => {
    const classes = useStyles();

    const getView = (status,title) => {
      // console.log(status);
      // console.log(title);
      if (status === 'loading') {
        return <CircularProgress />
      } else if (status === 'addOrderSuccess') {
        return (
          <React.Fragment>
            <CheckCircleIcon className={classes.successIcon}/>
              <Button className={classes.btn} variant="outlined" color="primary" onClick={refresh}>
                {chiLng.back}
            </Button>
          </React.Fragment>
        )
      } else if (status === 'fail') {
        return (
          <React.Fragment>
            <Typography variant="h6" component="h2">
              {title}
            </Typography>
              <Button className={classes.btn} variant="outlined" color="primary" onClick={refresh}>
                {chiLng.back}
            </Button>
          </React.Fragment>
        )
      }
    }
    const refresh = () => {
      window.location.reload()
    }
    return (
        <div className={classes.loading}>
            {
              getView(props.status, props.title)
            }
        </div>
    );
};