import * as ActionTypes from '../actions';
import lng from '../misc/lng';

const initialStateObj = {
    menu: {

    }
};

const menuReducer = (state = initialStateObj, action) => {
    switch(action.type) {
        case ActionTypes.INIT_MENU:
            return {
                ...state,
                menu: action.data,
            }
        default:
            return {...state};
    }
}

export default menuReducer;