import React from 'react';
import SpecSelectionList from './SpecSelectionList';


export default (props) => {
    return(
        <React.Fragment>
            {
                props.specList.map(
                    (spec, idx) => {
                        return (
                            <SpecSelectionList
                                spec={spec}
                                key={idx}
                                specIdx={idx}
                                handleChange={props.handleChange}
                            />
                        );
                    }
                )
            }
        </React.Fragment>
    );
};