import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import lng from '../../../misc/lng';
import envConst from '../../../misc/envConst';
import CartItemGrid from './CartItemGrid';
import DiscountDisplayGrid from './DiscountDisplayGrid';
import {setLoadingWrapper} from '../../../actions/dineIn';
import EditDialog from './EditDialog';
import {filterVoidOrders} from '../../../utils/orderFilterFunc';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const {chiLng} = lng;

const useStyles = makeStyles(() => ({
    wrapper: {
        backgroundColor: '#f1efef',
        marginBottom: '100px',
    },
    titleWrapper: {

    },
    title: {
        paddingLeft: '10px',
        paddingTop: '30px',
        paddingBottom: '10px'
    },
    subTitle: {
        opacity: '0.7',
    },
    editTitle: {
        opacity: '0',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '20px',
        alignItems: 'center',
        backgroundColor: 'white',
    },
    footer: {
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        height: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0 5px rgba(0, 0, 0, 0.8)',
        justifyContent: 'space-evenly'
    },
    addOrderBtn: {
        width: '90%',
        height: '40px',
        color: 'white',
        backgroundColor: '#666666',
        borderRadius: '10px',
        fontSize: '30px',
        lineHeight: '40px',
    },
    totalPriceWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    priceTotalTitle: {
        marginLeft: '10px',
    },
    priceTotal: {
        marginRight: '10px',
    },
    productData: {
        margin: '10px 0',
        backgroundColor: 'white',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    selectedProductData: {
        margin: '10px 0',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: 'rgb(192 199 194)',
    },
    productSummary: {
        margin: '10px 0',
        backgroundColor: 'white',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
  }));


export default (props) => {
    const classes = useStyles();
    const {
        productData, productSummary
    } = useSelector((store) => store.productsReducer);
    const {
        tableInCharge: {
            id,
            orders,
        },
        validOrders,
    } = useSelector((store) => store.dineInReducer);
    const {priceAfterDiscount, tagSetDiscountList} = productSummary;
    const [qtyDialog, setQtyDialog] = useState({
        open: false,
        data: {},
        pIdx: null,
    });
    const dispatch = useDispatch();

    const handleClickItem = (orderDetail, pIdx) => {
        console.log('handleClickItem')
        console.log(orderDetail, pIdx);
        if (qtyDialog.pIdx === pIdx) {
            setQtyDialog(
                {
                    open: false,
                    data: {},
                    pIdx: null,
                }
            );
        } else {
            setQtyDialog(
                {
                    open: true,
                    data: orderDetail,
                    pIdx,
                }
            );
        }
    }

    const handleCloseQtyDialog = () => {
        setQtyDialog(
            {
                open: false,
                data: {},
                pIdx: null,
            }
        );
    }

    const handleClose = () =>{
        props.handleClose();
    }
    const handleAddOrder = () => {
        dispatch(setLoadingWrapper('loading', ''));
        const orderTime = new Date();
        const orderData = {
            orderTime,
            orderDetails: productData.map(
                (meal) => {
                    return {
                        mealCategoryId: meal.mealCategoryId,
                        mealSubCategoryId: meal.mealSubCategoryId,
                        mealId: meal.mealId,
                        name: meal.name,
                        additionalOptionCategory: meal.additionalOptionCategory,
                        additionalOptionCategoryInUsed: meal.additionalOptionCategoryInUsed,
                        qty: meal.qty,
                        price: meal.price,
                        weekday: meal.weekday,
                        img: meal.img,
                        tag: meal.tag,
                        hasMenuSpec: meal.hasMenuSpec,
                        productSpec: meal.productSpec,
                        printerName: meal.printerName,
                        simpleOptions: meal.simpleOptions,
                    };
                }
            ),
        };
        console.log(orderData);
        const addOrderUrl =  `${envConst.API_ADD_ORDER}`;
        axios.post(addOrderUrl,
            {
                id,
                order: orderData,
                productSummary,
                replaceOriginal: false,
            }
        ).then(
            (res) => {
                dispatch(setLoadingWrapper('completed', ''));
                console.log('res')
                console.log(res)
                if (res && res.data && res.data.result && res.data.result.success) {
                    dispatch(setLoadingWrapper('addOrderSuccess', ''));

                } else {
                    dispatch(setLoadingWrapper('fail', chiLng.orderFailMsg));

                }
            },
            (fail) => {
                dispatch(setLoadingWrapper('fail', chiLng.orderFailMsg));
            }
        );
    }

    const getConfirmedOrderBox = () => {
        if (validOrders.length > 0) {
            return (
                <React.Fragment>
                    <div className={classes.productData}>
                        <Typography variant='h6' align='center'>
                            {chiLng.confirmedOrderTitle}
                        </Typography>
                    </div>
                    {
                        filterVoidOrders(orders).map(
                            (order, oIdx) => {
                                return order.orderDetails.map((orderDetail, idx) => {
                                    console.log('orderDetail')
                                    console.log(orderDetail)
                                    return (
                                        <div
                                            className={classes.productData}
                                            key={`confirmedOrderCartItemGrid_${oIdx}_${idx}`}
                                        >
                                            <CartItemGrid
                                                orderDetail={orderDetail}
                                                updateCart={() => {}}
                                                selectedItemIdx={0}
                                                handleSelectItem={() => {}}
                                                idx={idx}
                                            />
                                        </div>
                                    );
                                })
                            }
                        ).flatMap(order => order)
                    }
                </React.Fragment>
            )
        }
        return null;
    }

    const getReadyToOrderBox = () => {
        if (productData.length > 0) {
            return (
                <React.Fragment>
                    <div className={classes.productData}>
                        <Typography variant='h6' align='center'>
                            {chiLng.readyToOrderTitle}
                        </Typography>
                    </div>
                    {
                        productData.map((orderDetail, idx) => {
                            return (
                                <div
                                    className={qtyDialog.pIdx === idx ? classes.selectedProductData : classes.productData}
                                    key={`orderCartItemGrid_${idx}`}
                                    onClick={() => {handleClickItem(orderDetail, idx)}}
                                >
                                    <CartItemGrid
                                        orderDetail={orderDetail}
                                        updateCart={() => {}}
                                        selectedItemIdx={0}
                                        handleSelectItem={() => {}}
                                        idx={idx}
                                    />
                                </div>
                            );
                        })
                    }
                </React.Fragment>
            );
        }
        return null;
    }

    return(
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            onClose={handleClose}
            fullScreen
        >
            <div className={classes.wrapper}>
                <div className={classes.titleWrapper}>
                    <IconButton aria-label="back" onClick={handleClose}>
                        <KeyboardArrowLeftOutlinedIcon fontSize="large"/>
                    </IconButton>
                    <Typography className={classes.subTitle} variant='h5'>
                        {chiLng.shoppingCart}
                    </Typography>
                    <Typography className={classes.editTitle} variant='h5'>
                        {chiLng.edit}
                    </Typography>
                </div>
                
                {getReadyToOrderBox()}
                {getConfirmedOrderBox()}
                {
                    tagSetDiscountList.length > 0 ?
                    (
                        <div className={classes.productSummary}>
                            <DiscountDisplayGrid productSummary={productSummary} />
                        </div>
                    ) : null
                }


                <EditDialog open={qtyDialog.open} data={qtyDialog.data} pIdx={qtyDialog.pIdx} handleClose={handleCloseQtyDialog}/>

                <div className={classes.footer}>
                    <div className={classes.totalPriceWrapper}>
                        <Typography align='left' className={classes.priceTotalTitle} variant='h6'>
                            {`${chiLng.shoppingCartPriceTotal}`}
                        </Typography>
                        <Typography align='right' className={classes.priceTotal} variant='h6'>
                            {`$${priceAfterDiscount}`}
                        </Typography>
                    </div>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        classes={{containedPrimary: classes.addOrderBtn}}
                        disabled={productData.length <= 0}
                        onClick={handleAddOrder}
                    >
                        {`${chiLng.addOrder}`}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};