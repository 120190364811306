export function filterVoidOrders(orders) {
    // console.log('filterVoidOrders');
    const res = orders.map(
        (order) => {
            return {
                ...order,
                orderDetails: order.orderDetails.filter(orderDetail => !orderDetail.void),
            }
        }
    ).filter(order => order.orderDetails.length > 0);
    console.log(res)
    return res;
}

export function getVoidOrders(orders) {
    // console.log('getVoidOrders');
    const res = orders.map(
        (order) => {
            return {
                ...order,
                orderDetails: order.orderDetails.filter(orderDetail => orderDetail.void),
            }
        }
    ).filter(order => order.orderDetails.length > 0);
    return res;
}

// export function getOrderInSingleArray(orders) => {
//     orders.map(
//         (order) => {

//         }
//     );
// }