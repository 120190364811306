import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import {setAddMealDialog, setAddSpecMealDialog} from '../../../actions/dineIn';
import Typography from '@material-ui/core/Typography';
import lng from '../../../misc/lng';
import envConst from '../../../misc/envConst';

const { chiLng } = lng;

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
        },
        card: {
            width: '90%',
            margin: '20px auto',
            display: 'flex'
        },
        details: {
            flex: '2',
            height: '80px',
            display: 'flex',
            flexDirection: 'column',
        },
        img: {
            width: 110,
            height: 80,
            objectFit: 'cover'
        },
        title: {
            marginTop: '5px',
            marginLeft: '10px',
            fontSize: '16px'
        },
        price: {
            marginTop: '5px',
            marginLeft: '10px',
            color: '#d2691e',
            fontWeight: '600',
            fontSize: '15px'
        },
        outOfStock: {
            opacity: 0.5,
        },
    }
});

// Provide layout for 1st and 2nd menu
const MealBox = (props) => {
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const {meal, disabled} = props;
    const [img, setImg] = useState(envConst.DEFAULT_IMG_PATH);

    
    useEffect(() => {
        if (meal && meal.img) {
            setImg(`${envConst.IMAGE_PATH}${meal.img}`);
        } else {
            setImg(envConst.DEFAULT_IMG_PATH);
        }
    }, []);
    
    const handleAddMeal = () => {
        if (meal.hasMenuSpec === 'true') {
            dispatch(setAddSpecMealDialog(true, meal))
        } else {
            dispatch(setAddMealDialog(true, meal))
        }
    }

    const handleImgError = () => {
        console.log('handleImgError')
        setImg(envConst.DEFAULT_IMG_PATH);
    };

    return (
        <div
            className={classes.wrapper}
            onClick={() => {
                if (props.disabled) return;
                handleAddMeal();
            }}
        >
            <Card className={`${classes.card} ${props.disabled ? classes.outOfStock : ''}`}>
                <CardMedia
                    component="img"
                    alt={meal.name}
                    src={img}
                    className={classes.img}
                    onError={handleImgError}
                />
                <div className={classes.details}>
                    <Typography align='left' variant="h6" className={classes.title}>
                        {`${meal.name}${props.disabled ? chiLng.outOfStock : ''}`}
                    </Typography>
                    <Typography align='left' variant="subtitle1" className={classes.price}>
                        {`$${meal.price}`}
                    </Typography>
                </div>
            </Card>
        </div>
    );
};

export default MealBox;