import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MealBox from './MealBox';
import {useDispatch, useSelector} from 'react-redux';
// import BigMenuCustMealList from './BigMenuCustMealList';
import Typography from '@material-ui/core/Typography';
import lng from '../../../misc/lng';
const daylist = ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat']

const weekDayNum = new Date().getDay();

const curWeekday = daylist[weekDayNum];

const { chiLng } = lng;

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
        leftMenu: {
            height: 'fit-content',
            minHeight: '100%',
            marginTop: '0px'
        },
        menuItem: {
            justifyContent: 'flex-start',
            fontSize: '24px',
            fontWeight: '400',
            padding: '5px',
            paddingTop: '14px',
            paddingBottom: '14px',
            margin: '10px',
            borderRadius: '30px',
        },
        menuItemSelected: {
            justifyContent: 'flex-start',
            color: 'white',
            fontSize: '24px',
            fontWeight: '600',
            padding: '5px',
            paddingTop: '14px',
            paddingBottom: '14px',
            backgroundColor: theme.palette.primary.main,
            margin: '10px',
            borderRadius: '30px',
        },
        subMenuPanel: {},
        mainMenuWrapper: {
            overflowX: 'auto',
            display: 'flex',
            flexShrink: '0',
            height: '55px',
            width: '95%',
            margin: '0 auto',
        },
        mainMenuItem: {
            minWidth: 'max-content',
            padding: '6px 10px',
            margin: '4px',
            fontSize: '24px',
            fontWeight: '400',
        },
        mainMenuItemSelected: {
            minWidth: 'max-content',
            padding: '6px 10px',
            margin: '4px',
            backgroundColor: '#999999',
            borderRadius: '10px',
            color: 'white',
            fontSize: '24px',
            fontWeight: '600',
        },
        mainContent: {
            padding: '15px',
        },
        none: {
            display: 'none',
        },
        itemWrapper: {
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 120px)'
        },
    }
});



// Provide layout for 1st and 2nd menu
const MenuWrapper = (props) => {
    const classes = useStyles(props);
    const [mainCatList, setMainCatList] = useState([]);
    const [subCatList, setSubCatList] = useState([]);
    const [mainCat, setMainCat] = useState('');
    const [subCat, setSubCat] = useState('');
    const [mealList, setMealList] = useState([]);

    const {
        menu
    } = useSelector((store) => store.menuReducer);

    useEffect(() => {
        console.log(menu);
        setMainCatList(menu.mealCategoryList);
        setSubCatList(menu.mealSubCategoryList);
        setMainCat(menu.mealCategoryList[0]);
        setSubCat(menu.mealSubCategoryList[0]);
    }, []);

    useEffect(() => {
        console.log('menu');
        console.log(menu);
        const {entireMap} = menu;
        console.log(entireMap);
        console.log(mainCat.mealCategoryId);
        console.log(subCat.mealSubCategoryId);
        if (
            entireMap[mainCat.mealCategoryId] &&
            entireMap[mainCat.mealCategoryId][subCat.mealSubCategoryId] &&
            entireMap[mainCat.mealCategoryId][subCat.mealSubCategoryId].meals
        ) {
            setMealList(entireMap[mainCat.mealCategoryId][subCat.mealSubCategoryId].meals);
        } else {
            setMealList([]);
        }
    }, [subCat]);

    return (
        <div className={classes.wrapper}>
            {/* <div className={classes.none}>
                {mainCatList.map(
                    (item, i) => {
                        console.log(item);
                        return (
                            <div
                                className={item.mealCategoryId === mainCat.mealCategoryId ? classes.mainMenuItemSelected : classes.mainMenuItem}
                                key={i}
                                onClick={() => {setMainCat(item)}}
                            >
                                {item.name}
                            </div>
                        );
                    }
                )}
            </div> */}
            <div className={classes.mainMenuWrapper}>
                {subCatList.filter(
                    subCatItem => typeof subCatItem.showMenuToClient === 'undefined' || 
                    subCatItem.showMenuToClient === true
                ).map(
                    (item, i) => {
                        return (
                            <div
                                className={item.mealSubCategoryId === subCat.mealSubCategoryId ? classes.mainMenuItemSelected : classes.mainMenuItem}
                                key={i}
                                onClick={() => {setSubCat(item)}}
                            >
                                {item.name}
                            </div>
                        );
                    }
                )}
            </div>
            <div className={classes.itemWrapper}>
                {
                    mealList.filter(
                        (meal) => {
                            if (meal.weekday !== 'unlimited' && meal.weekday !== curWeekday) {
                                return false;
                            }
                            return true;

                        }
                    ).map(
                        (meal) => {
                            return meal.showMenuToClient && meal.showMenuToClient === 'hide' ? 
                                null : (
                                    <MealBox 
                                        meal={meal}
                                        key={meal.mealId}
                                        disabled={meal.outOfStock}
                                    />
                                );
                        }
                    )
                }
            </div>
        </div>
    );
};

export default MenuWrapper;