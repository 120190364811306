import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import LoadingWrapper from '../components/DineIn/LoadingWrapper';
import FailLoadingWrapper from '../components/DineIn/FailLoadingWrapper';
import TakeoutPage from '../components/takeout/TakeoutPage';
import envConst from '../misc/envConst';
import {
    initTakeoutPage,
    initTakeoutFail,
    setShoppingCart
} from '../actions/takeout';
import { initMenu } from '../actions/menu';
// import BigMenuCustCatWrapper from '../components_bk/DineIn/Menu/BigMenuCustCatWrapper';
// import AddSpecMealDialog from '../components_bk/bigMenu/AddSpecMealDialog';
// import CartBox from '../components_bk/DineIn/CartBox';
import lng from '../misc/lng';
import { ConsoleNetworkOutline } from 'mdi-material-ui';

const {chiLng} = lng;

export default () => {
    const dispatch = useDispatch();
    // const {
    //     initStatus
    // } = useSelector((store) => store.dineInReducer);
    const {
        loadingWrapper,
        initStatus
    } = useSelector((store) => store.takeoutReducer);
    useEffect(() => {
        console.log('Client Takeout Page');
            const menuTargetUrl =  `${envConst.API_GET_ENTIRE_MENU}`;
            axios.post(menuTargetUrl,
                {
                    id: "sh01" 
                }
            ).then((response) => {
                console.log('Entire Menu api call Response');
                console.log(response);
                if (response.data && response.data.success && response.data.result) {
                    const urlParams = new URLSearchParams(window.location.search);
                    const sessionId = urlParams.get('sessionId');
                    dispatch(initMenu(response.data.result));
                    dispatch(initTakeoutPage());
                    if (sessionId) {
                        async function fetchData() {
                            try {
                                const url = `${envConst.GET_ORDER_URL}`;
                                const response = await axios.post(url, { sessionId });
                                if (response.data.success) {
                                    const data = response.data.data;
                                    if (
                                        data && 
                                        data.orders &&
                                        data.orders[0] &&
                                        data.orders[0].orderDetails
                                    ) {
                                        dispatch(setShoppingCart(data.orders[0].orderDetails));
                                    }
                                }
                            } catch (error) {
                                console.error(error); // handle the error
                            }
                        }
                        fetchData();

                    }


                } else {
                    console.log('Get menu error');
                    dispatch(initTakeoutFail(chiLng.failToLoadMenu));
                }
            });

            
            // dispatch(initFail(chiLng.failToLoadTable));
            // dispatch(initFail(chiLng.failToLoadTable))

        return () => {
            console.log('dineInPage close');
        }
    }, [dispatch]);

    const getPage = () => {
        if (initStatus === 'loading') {
            return (<LoadingWrapper status={loadingWrapper.status} title={loadingWrapper.title}/>);
        } else if (initStatus === 'initFail') {
            return (<FailLoadingWrapper status={loadingWrapper.status} title={loadingWrapper.title}/>);
        } else {
            return <TakeoutPage />;
        }
    }

    return (
        <div>
            {getPage()}
        </div>
    );
};