import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import envConst from '../misc/envConst';
import lng from '../misc/lng';
import { PhoneReturnOutline } from 'mdi-material-ui';
import OrderDetail from '../components/takeout/OrderDetail';

const useStyles = makeStyles(() => ({
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // this makes it center vertically
    },
}));

export default (props) => {
    const classes = useStyles();
    const [orderDetail, setOrderDetail] = useState(null);
    const [success, setSuccess] = useState(false);
    const [title, setTitle] = useState('Loading...');
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('sessionId');
        const hash = urlParams.get('hash');
        const success = urlParams.get('success');
        setSuccess(success);
        if (sessionId || hash) {
            async function fetchData() {
                try {
                    const url = `${envConst.GET_ORDER_URL}`;
                    const response = await axios.post(url, { sessionId, hash });

                    if (response.data.success) {
                        setOrderDetail(response.data.data);
                    }
                } catch (error) {
                    console.error(error); // handle the error
                }
            }
            fetchData();
        }
    }, []);

    useEffect(() => {
        console.log('orderDetail');
        console.log(orderDetail);
    }, [orderDetail, success]);

    if (!orderDetail) {
        return (
            <Typography variant="h6" className={classes.loadingWrapper}>{title}</Typography>
        );
    }
    return (
        <div className={classes.root}>
            <OrderDetail orderData={orderDetail} />
        </div>
    );
};
