import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import OrderDetail from '../../MealClass/OrderDetail';

const useStyles = makeStyles((theme) => {
    return {
        gridItem: {
            width: '200px',
            display: 'inline-block',
            border: `1px solid ${theme.palette.allDay.main}`,
            minHeight: '180px',
            objectFit: 'contain',
            marginBottom: '1em',
        },
        kitchenTitle: {
            backgroundColor: '#3700B3',
            color: '#FFFFFF'
        },
        orderText: {
            wordWrap: 'break-word'
        }
    }
})

const formatDate = (dateString) => {
    let date = moment(dateString);

    return date.format('YYYY-MM-DD HH:mm:ss');
}

function getLastFourLetters(str) {
    if (typeof str !== 'string') {
        return '';
    }
    return str.slice(-4);
}

const LocalOrderGrid = ({realTimeOrder, handleMonitorGridClick}) => {
    const classes = useStyles();
    const { orderId } = realTimeOrder;
    const orderTime = formatDate(new Date(realTimeOrder.orders[0].orderTime));
    const id = realTimeOrder.id;
    const orderDetails = realTimeOrder.orders[0].orderDetails.map(
        (detail) => {
            return new OrderDetail(detail);
        }
    );
    orderDetails.forEach(
        (detail) => {
            console.log(detail.getPrintStringArray());
        }
    );
    return (
        <div
            className={classes.gridItem}
            onClick={() => { handleMonitorGridClick(id, getLastFourLetters(orderId), 'local') }}
            key={id}
        >
            <Typography className={classes.kitchenTitle} variant="h5"><b>{getLastFourLetters(orderId)}</b></Typography>
            <Typography classes={{ root: classes.typoRoot }} variant="body1">{orderTime}</Typography>

            {
                orderDetails.map(
                    (detail, _idx) => {
                        return detail.getPrintStringArray().map(
                            (str, _idx) => {
                                return (
                                    <Typography key={_idx} align="left" classes={{ root: classes.orderText }} variant="subtitle1">
                                        {str}
                                    </Typography>
                                );
                            }
                        )
                    }

                )
            }

        </div>
    );
};


export default LocalOrderGrid;
