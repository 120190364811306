import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import lng from '../../misc/lng';

const {chiLng} = lng;

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            width: '100%',
        },
        optWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '10px',
            paddingBottom: '10px',
            backgroundColor: 'white',
        },
        title: {
            paddingLeft: '10px',
            paddingTop: '30px',
            paddingBottom: '10px'
        },
        optBox: {
            backgroundColor: '#666666',
            color: 'white',
            padding: '5px',
            padding: '10px',
            margin: '5px',
            borderRadius: '10px',
            // border: '4px solid white',
        },
        optBoxSelected: {
            // backgroundColor: '#666666',
            backgroundColor: 'rgb(47 58 50)',
            color: 'white',
            padding: '10px',
            margin: '5px',
            borderRadius: '10px',
            // border: '4px solid black',
        },
        titleWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            marginRight: '20px',
        },
        subTitle: {
            opacity: '0.7',
        },
        subTitleError: {
            color: 'red',
            opacity: '0.7',
        },
        outOfStock: {
            textDecoration: "line-through",
            color: theme.palette.disabled,
            opacity: 0.5,
        }
    }
});

const SpecSelectionList = (props) => {
    const classes = useStyles();

    const handleClick = (oIdx) => {
        props.handleChange(oIdx, props.specIdx, props.spec.maxQty, props.spec.minQty);
    }

    const getSelected = (list) => {
        return list.filter(l => l.selected).length;
    };

    const isSpecFulfilled = (spec) => {
        const {maxQty, minQty} = spec;
        const numOfSelected = getSelected(spec.specOptions);
        if (maxQty ===  minQty) {
            return numOfSelected === maxQty;
        } else if ( numOfSelected <= maxQty && numOfSelected >= minQty) {
            return true;
        }
        return false;
    }
    
    const gethelperText = (spec) => {
        const {maxQty, minQty} = spec;
        const numOfSelected = getSelected(spec.specOptions);
        const isFulfilled = isSpecFulfilled(spec);
        if (maxQty ===  minQty) {
            return (
                <Typography className={isFulfilled ? classes.subTitle : classes.subTitleError} variant='subtitle2'>
                    {`${chiLng.specLimitHelperTextSpecifyNumberPT1}${maxQty}${chiLng.specLimitHelperTextSpecifyNumberPT2}`}
                </Typography>
            );
        }
        return (
            <Typography className={isFulfilled ? classes.subTitle : classes.subTitleError} variant='subtitle2'>
                {`
                    ${chiLng.specLimitHelperTextSpecifyNumberPT5}
                    ${minQty}
                    ${chiLng.specLimitHelperTextSpecifyNumberPT6}
                    ${maxQty}
                    ${chiLng.specLimitHelperTextSpecifyNumberPT7}
                `}
            </Typography>);
    }

    return(
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant='h5'>
                    {props.spec.specTitle}
                </Typography>
                {
                    gethelperText(props.spec)
                }
            </div>
            <div className={classes.optWrapper}>
                {
                    props.spec.specOptions.map(
                        (opt, idx) => {
                            return opt.showMenuToClient && opt.showMenuToClient === 'hide' ? 
                            null : (
                                <div
                                    className={`${opt.selected ? classes.optBoxSelected : classes.optBox} ${opt.outOfStock ? classes.outOfStock : ''}`}
                                    key={idx}
                                    onClick={() => {
                                        if  (opt.outOfStock) return;
                                        handleClick(idx);
                                    }}
                                >
                                    {opt.specName}
                                </div>
                            );
                        }
                    )
                }
            </div>
        </div>
    );
};

export default SpecSelectionList;