import * as ActionTypes from './index';

export const initTakeoutPage = (data) => {
    return {
        type: ActionTypes.INIT_TAKEOUT_PAGE,
        data,
    };
}

export const initTakeoutFail = (title) => {
    return {
        type: ActionTypes.INIT_TAKEOUT_FAIL,
        data: {
            title,
        },
    };
}

export const setShoppingCart = (order) => {
    return {
        type: ActionTypes.SET_SHOPPING_CART,
        data: {
            order,
        },
    };
};

