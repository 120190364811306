
import SpecOption from './SpecOption';

class Spec {
    constructor(data = {}) {
        this.mealSpecId = data.mealSpecId || null;
        this.specTitle = data.specTitle || null;
        this.minQty = data.minQty || 0;
        this.maxQty = data.maxQty || 0;
        this.specOptions = data.specOptions ? data.specOptions.map(option => new SpecOption(option)) : [];
        this.mealId = data.mealId || null;
        this.qtyLimit = data.qtyLimit || 0;
    }
    
    serialize() {
        return {
            mealSpecId: this.mealSpecId,
            specTitle: this.specTitle,
            minQty: this.minQty,
            maxQty: this.maxQty,
            specOptions: this.specOptions.map(option => option.serialize()),
            mealId: this.mealId,
            qtyLimit: this.qtyLimit
        };
    }

    getTotalPrice() {
        return this.specOptions.reduce((acc, cur) => {
            return acc + cur.getTotalPrice();
        }, 0);
    }

    getPrintString() {
        return this.specOptions.map(option => option.specName).join(', ');
    }

}

export default Spec;