import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SimpleOptionList from './menu/SimpleOptionList';
import SpecSelectionListWrapper from './SpecSelectionListWrapper';
import IconButton from '@material-ui/core/IconButton';
import {setAddSpecMealDialog, addCartItemSeparately} from '../../actions/dineIn';
import CloseIcon from '@material-ui/icons/HighlightOffOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import {getSpecMealPrice, getAllSpecPrice} from '../../utils/meal';
import lng from '../../misc/lng';
import envConst from '../../misc/envConst';

const {chiLng} = lng;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles(() => ({
    root: {

    },
    img: {
        width: '100%',
    },
    content: {
        marginBottom: '150px',
        backgroundColor: '#f1efef'
    },
    title: {
        marginTop: '10px',
        marginLeft: '10px'
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px'
    },
    price: {
        marginRight: '20px',
        marginTop: '10px',
        color: '#d2691e',
        fontWeight: '600',
    },
    footer: {
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        height: '130px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0 5px rgba(0, 0, 0, 0.8)',
    },
    qtyBtnWrapper: {
        margin: '10px auto',
        display: 'flex',
        alignItems: 'center',
    },
    qty: {
        color: '#d2691e',
        fontWeight: '600',
        fontSize: '30px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    addOrderBtn: {
        width: '70%',
        height: '40px',
        color: 'white',
        backgroundColor: '#666666',
        borderRadius: '10px',
        fontSize: '30px',
        lineHeight: '40px',
    },
    btnBack: {
        position: 'absolute',
        top: '10px',
        right: '15px',
        color: 'white',
    },
  }));


export default (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        addSpecMealDialog: {
            open, data
        }
    } = useSelector((store) => store.dineInReducer);

    
    const {
        menu: {simpleOptionList}
    } = useSelector((store) => store.menuReducer);
    
    const [qty, setQty] = useState(1);
    const [simpleOptList, setSimpleOptList] = useState([]);
    const [specList, setSpecList] = useState([]);
    const [selectedSpecList, setSelectedSpecList] = useState([]);
    const [img, setImg] = useState(envConst.DEFAULT_IMG_PATH);

    const handleClickSimpleOpt = (opt) => {
        setSimpleOptList(
            simpleOptList.map(
                (sOpt) => {
                    if (opt.id === sOpt.id) {
                        return {
                            ...sOpt,
                            selected: !sOpt.selected,
                        };
                    }
                    return sOpt;
                }
            )
        );
    }

    const getSelected = (list) => {
        return list.filter(l => l.selected).length;
    };

    const isSpecFulfilled = (spec) => {
        const {maxQty, minQty} = spec;
        const numOfSelected = getSelected(spec.specOptions);
        if (maxQty ===  minQty) {
            return numOfSelected === maxQty;
        } else if ( numOfSelected <= maxQty && numOfSelected >= minQty) {
            return true;
        }
        return false;
    }
    
    const isConfirmDisabled = () => {
        let isFulfill = true;
        specList.forEach(
            (spec) => {
                if (!isSpecFulfilled(spec)) {
                    isFulfill = false;
                }
            }
        );
        return !isFulfill;
    }

    const handleQtyAdd = () => {
        setQty(qty + 1);
    }

    const handleQtyReduce = () => {
        if (qty <= 1) return;
        setQty(qty - 1);
    }

    const handleClose = () =>{
        dispatch(setAddSpecMealDialog(false, {}));
    }
    // const imgSrc  = () => {
    //     console.log('imgSrc')
    //     console.log(props.meal);
    //     if (props.meal) {
    //         return `./assets/mealImages/${props.meal.img}`;
    //     }
    //     return envConst.DEFAULT_IMG_PATH;
    // }

    const handleConfirm = () => {
        console.log('handleConfirm');
        const mealToAdd = {
            ...data,
            productSpec: [{
                priceForOne: getAllSpecPrice(selectedSpecList) + data.price,
                qty,
                spec: selectedSpecList
            }],
            simpleOptions: simpleOptList.filter(
                so => so.selected
            ).map(
                so => so.optionName
            ),
        };
        console.log(mealToAdd);
        dispatch(addCartItemSeparately(mealToAdd));
        handleClose();
    }

    const getTotalPrice = () => {
        return getSpecMealPrice(
            {
                ...data,
                specList: selectedSpecList,
            }
        ) * qty;
    }

    useEffect(
        () => {
            setSelectedSpecList(
                specList.map(
                    (spec) => {
                        return {
                           ...spec,
                           specOptions: spec.specOptions.filter(opt => opt.selected),
                        };
                    }
                ).filter(spec => spec.specOptions.length > 0)
            );
        }, [specList]
    );

    useEffect(
        () => {
            console.log('selectedSpecList');
            console.log(selectedSpecList);
        }, [selectedSpecList]
    );

    // onEnter
    const onEnter = () => {
        if (!open) {
            return;
        }
        console.log('onEnter --')
        console.log(data);
        // if (data.imgFile) {
        //     setImg(`data:image/jpeg;base64, ${data.imgFile}`);
        // } else {
        //     setImg(envConst.DEFAULT_IMG_PATH);
        // }
        if (data && data.img) {
            setImg(`${envConst.IMAGE_PATH}${data.img}`);
        } else {
            setImg(envConst.DEFAULT_IMG_PATH);
        }
        
        setQty(1);
        setSimpleOptList(
            simpleOptionList.map(
                (opt) => {
                    return {
                        ...opt,
                        selected: false,
                    };
                }
            )
        );
        console.log('data');
        console.log(data);
        if (data.specList) {
            setSpecList(
                data.specList.map(
                    (spec) => {
                        return {
                            ...spec,
                            specOptions: spec.specOptions.map(
                                (opt) => {
                                    return {
                                        ...opt,
                                        selected: false,
                                    };
                                }
                            )
                        };
                    }
                )
            );
        }
    };
    
    const handleSpecChange = (optIdx, specIdx, maxQty,  minQty) => {
        const newSpecList = specList.map(
            (spec, sIdx) => {
                if (specIdx === sIdx) {
                    return {
                        ...spec,
                        specOptions: spec.specOptions.map(
                            (opt, oIdx) => {
                                // only allow selected one option
                                if (maxQty === minQty && maxQty === 1)  {
                                    if (oIdx === optIdx) {
                                        return {
                                            ...opt,
                                            selected: true,
                                        };
                                    }
                                    return {
                                        ...opt,
                                        selected: false,
                                    };
                                }
                                if (oIdx === optIdx) {
                                    return {
                                        ...opt,
                                        selected: !opt.selected,
                                    };
                                }
                                return opt;
                            }
                        )
                    };
                }
                return spec;
            }
        );
        setSpecList(newSpecList);

    }

    const handleImgError = () => {
        console.log('handleImgError')
        setImg(envConst.DEFAULT_IMG_PATH);
    };

    return(
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            fullScreen
            TransitionProps={{onEnter: onEnter}}
        >
            <IconButton aria-label="back" className={classes.btnBack} onClick={handleClose}>
                <CloseIcon fontSize="large" />
            </IconButton>
            <div className={classes.content}>
                <CardMedia
                    component="img"
                    alt={data.name}
                    image={img}
                    className={classes.img}
                    onError={handleImgError}
                />
                <div className={classes.titleWrapper}>
                    <Typography className={classes.title} variant='h4'>
                        {data.name}
                    </Typography>
                    <Typography className={classes.price} variant='h4'>
                        ${data.price}
                    </Typography>
                </div>
                <Divider variant="middle" />
                <SpecSelectionListWrapper
                    specList={specList}
                    handleChange={handleSpecChange}
                />
                <SimpleOptionList 
                    simpleOptionList={simpleOptList}
                    handleClickSimpleOpt={handleClickSimpleOpt}
                />
            </div>
            <div className={classes.footer}>
                <div className={classes.qtyBtnWrapper}>
                    <div className={classes.qtyAdd}>
                        <IconButton aria-label="add" onClick={handleQtyAdd}>
                            <AddCircleOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                    </div>
                    <div className={classes.qty}>
                        {qty}
                    </div>
                    <div className={classes.qtyReduce} onClick={handleQtyReduce}>
                        <IconButton aria-label="reduce">
                            <RemoveCircleOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                    </div>
                </div>
                {/* <Typography
                    align='center'
                    className={classes.addOrderBtn}
                    variant='h5'
                    onClick={handleConfirm}
                >
                    ADD
                </Typography> */}
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    classes={{containedPrimary: classes.addOrderBtn}}
                    onClick={handleConfirm}
                    disabled={isConfirmDisabled()}
                >
                  {`${chiLng.addOrder} $${getTotalPrice()}`}
                </Button>
            </div>
        </Dialog>
    );
};