import envConstDev from './envConst.dev';
import envConstProd from './envConst.prod';
import lng from './lng';
const envConst = process.env.NODE_ENV === 'development' ? envConstDev : envConstProd;

// Common functions for both dev and prod 
// Named export
export const menuNameExtractHelper = () => {
    const dayMapper = (dayNo) => {
        const dayName = [
            'monday', //sunday count as monday
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'monday' // saturaday count as monday
        ]
        return dayName[dayNo];
    }
    const today = new Date();
    return dayMapper(today.getDay());
};

export const defaultVal = {
    kitchenMonitorDefVal: [
        {
            id: "OO-99999",
            status: "preparing",
            orderTime: new Date(),
            orders: [
                {
                    formattedMealName: lng.sysVar.adminOrderMonitorDef.formattedMealName,
                    qty: lng.sysVar.adminOrderMonitorDef.qty,
                },
            ],
        },
    ],
};

export default envConst;