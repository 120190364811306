const lng = {
    chiLng: {
        toolbarTitle: "GOOD MAN EAT",
        simpleOptTitle: "口味",
        notNecessaryToChoose: "選填",
        specLimitHelperTextSpecifyNumberPT1: "請選擇",
        specLimitHelperTextSpecifyNumberPT2: "個選項",
        specLimitHelperTextSpecifyNumberPT3: "請選擇不多於",
        specLimitHelperTextSpecifyNumberPT4: "請選擇不少於",
        specLimitHelperTextSpecifyNumberPT5: "可選",
        specLimitHelperTextSpecifyNumberPT6: "至",
        specLimitHelperTextSpecifyNumberPT7: "個選項",
        addOrder: "加入",
        failToLoadTable: "台號錯誤，請聯絡店員。",
        checkShoppingCart: "查看購物車",
        edit: "編輯",
        done: "完成",
        shoppingCart: "購物車",
        shoppingCartPriceTotal: "訂單總額",
        discounTitle: "優惠",
        orderFailMsg: "出現錯誤，請聯絡店員。",
        qtyDialogConfirm: "確定",
        outOfStock: "(售罄)",
        confirmedOrderTitle: "已下單項目",
        readyToOrderTitle: "準備下單",
        back: "返回",
        failToLoadMenu: "準備中，請稍候再試。",
        kitchenMonTitle: "待處理訂單",
        kitchenMonOnlineOrder: "網上訂單",
        kitchenMonlocalOrder: "本地訂單",
        kitchenMonAll: "全部", 
        pay: "付款",
        orderTime: "下單時間",
        orderStatus: {
            PaymentCompleted: "已付款",
            WaitingForPickup: "待取餐",
            OrderCompleted: "已取餐",
            SessionExpired: "訂單過期",
            OrderError: "訂單錯誤",
        },
        errDoOrderData: "無法取得訂單資料",
        confirmOrderReadyDialogTitle: "確認訂單?",
        confirm: "確認",
        cancel: "取消",
        phoneNoLabel: "電話號碼",
        emailLabel: "電郵地址",
        contactInfoDialogTitle: "聯絡資料",
        //We'd love to keep you updated about your order! Please enter your email address or phone number below. We'll send you a notification as soon as your order is ready.
        contactInfoDescription: "我們希望能及時更新您的訂單狀態！請在下方輸入您的電子郵件地址或手機號碼。一旦您的訂單準備就緒，我們會向您發送通知。",
        or: "或",
    },
    sysVar: {
        adminOrderMonitorDef: {
            formattedMealName: "菜式名稱",
            qty: 1,
        },
    },
};

export default lng;