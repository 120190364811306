import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import lng from '../../../misc/lng';


const { chiLng } = lng;
const useStyles = makeStyles(() => ({
    wrapper: {

        marginTop: '10px',
        transition: 'height 0.5s',
        borderRadius: '5px',
        overflowY: 'auto',
    },
    wrapperSm: {
        width: '100%',
        marginTop: '10px',
        transition: 'height 0.5s',
        borderRadius: '5px',
        overflowY: 'auto',
    },
    wrapperSm: {
        width: '100%',
        marginTop: '10px',
        transition: 'height 0.5s',
        borderRadius: '5px',
        overflowY: 'auto',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        marginBottom: '5px',
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    priceAfterDiscountWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 5px',
        marginTop: '10px',
    },
    openBtn: {
        padding: '0 12px'
    },
    title: {
        opacity: '0.7',
        // marginTop: '10px'
    }
}));

const DiscountDisplayGrid = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const {priceAfterDiscount, totalPrice, discountPrice, tagSetDiscountList} = props.productSummary;


    const getPriceFormula = () => {
        if (discountPrice > 0) {
            return `$(${totalPrice} - ${discountPrice})`
        }
        else {
            return '';
        }
    }
    const getWrapperClasses = () => {
        if (props.width === 'sm') {
            return classes.wrapperSm
        } else if (props.width === 'md') {
            return classes.wrapperMd
        }
        return classes.wrapper;
    }
    return (
        <div className={getWrapperClasses()}>
            <div>
                <React.Fragment>
                    
                    <div className={classes.titleWrapper}>
                        <Typography
                            variant="h6"
                            className={classes.title}
                        >
                            {chiLng.discounTitle}
                        </Typography>
                    </div>
                    <Divider />
                    {
                        tagSetDiscountList.map(
                            (discount) => {
                                return (
                                    <div className={classes.contentWrapper} key={discount.id}>
                                        <Typography
                                            variant="h6"
                                            className={classes.title}
                                        >
                                            {`${discount.name} x ${discount.qty}`}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            className={classes.price}
                                        >
                                            {`- $${discount.price * discount.qty}`}
                                        </Typography>
                                    </div>
                                );
                            }
                        )
                    }
                </React.Fragment>
            </div>
        </div>
    );
};

DiscountDisplayGrid.propTypes = {
    width: PropTypes.string,
};

DiscountDisplayGrid.defaultProps = {
    width: 'sm'
};
export default DiscountDisplayGrid;