import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CartBox from '../DineIn/shoppingCart/CartBox';
import ShoppingCartDialog from './shoppingCart/ShoppingCartDialog';
import MenuWrapper from '../DineIn/menu/MenuWrapper';
import AddMealDialog from '../DineIn/AddMealDialog';
import AddSpecMealDialog from '../DineIn/AddSpecMealDialog';
import {updateDiscount} from '../../actions/dineIn';

import lng from '../../misc/lng';

const {chiLng} = lng;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
    },
    topPanel: {
        backgroundColor: '#666666',
        height: '65px',
    },
    content: {
        // overflow: 'auto',
        flex: '1',
        paddingBottom: '75px',
    },
    footer: {
        // position: 'fixed',
        // bottom: '0',
        // left: '0',
        // height: '70px',
        // width: '100%',
        // backgroundColor: 'white',
        // boxShadow: '0px 0 5px rgba(0, 0, 0, 0.8)',
        // display: 'flex',
        // // flexDirection: 'column',
        // alignItems: 'center',
    },
    title: {
        marginLeft: '10px',
        color: 'white'
    }
  }));


export default (props) => {
    const classes = useStyles();
    const {
        productData
    } = useSelector((store) => store.productsReducer);
    const {
        tableInCharge: {
            orders,
        },
    } = useSelector((store) => store.dineInReducer);
    const dispatch = useDispatch();
    const [shoppingCartOpen, setShoppingCartOpen] = useState(false);
    useEffect(
        () => {
            dispatch(updateDiscount(orders));
        }, [productData]
    );
    return(
        <div className={classes.root}>
            <ShoppingCartDialog
                open={shoppingCartOpen}
                handleClose={() => {setShoppingCartOpen(false)}}
            />
            <AddMealDialog />
            <AddSpecMealDialog />
            <div className={classes.topPanel}>
                <Typography
                    variant='h3'
                    align='center'
                    className={classes.title}
                >
                    ALL DAY
                </Typography>
            </div>
            <div className={classes.content}>
                <MenuWrapper />
            </div>
            <div className={classes.footer} onClick={() => {setShoppingCartOpen(true)}}>
                <CartBox />
            </div>
            
        </div>
    );
};