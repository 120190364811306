import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import lng from '../../misc/lng';

const {chiLng} = lng;

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));


export default (props) => {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <div>
            <Typography variant="h6" component="h2">
                {props.title}
            </Typography>
            </div>
        </div>
    );
};