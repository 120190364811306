import * as ActionTypes from '../actions';
import lng from '../misc/lng';
import {filterVoidOrders} from '../utils/orderFilterFunc';

const initialStateObj = {
    initStatus: 'loading',
    // id: '',
    // openTime: '',
    // numberOfPeople: 0,
    // orders: [],
    // runtimeHash: '',
    // status: '',
    // tableName: '',
    // tableType: '',
    // tableInCharge: {
    //     id: '',
    //     numberOfPeople: 0,
    //     openTime: '',
    //     orders: [],
    //     productSummary: {},
    //     runtimeHash: '',
    //     status: '',
    //     tableName: '',
    //     tableType: '',
    // },
    // validOrders: [],
    loadingWrapper: {
        status: 'loading',
        title: '',
    },
    failLoadingWrapper: {
        status: 'loading',
        title: '',
    },
    // msgDialog: {
    //     open: false,
    //     msg: '',
    // },
    // addSpecMealDialog: {
    //     open: false,
    //     data: {},
    // },
    // addMealDialog: {
    //     open: false,
    //     data: {},
    // }
};


const takeoutReducer = (state = initialStateObj, action) => {
    switch(action.type) {
        case ActionTypes.INIT_TAKEOUT_PAGE:
            return {
                ...state,
                initStatus: 'completed',
                // // ...action.data,
                loadingWrapper: {
                    status: 'completed',
                    title: '',
                },
                // tableInCharge: {
                //     ...action.data
                // },
                // validOrders: filterVoidOrders(action.data.orders),
            }
        case ActionTypes.INIT_TAKEOUT_FAIL:
            return {
                ...state,
                initStatus: 'initFail',
                loadingWrapper: {
                    status: 'initFail',
                    title: action.data.title,
                },
                // validOrders: [],
                // failLoadingWrapper: {
                //     status: 'initFail',
                //     title: action.data.title,
                // }
            }
        // case ActionTypes.SET_FAIL_LOADING_WRAPPER:
        //     return {
        //         ...state,
        //         failLoadingWrapper: {
        //             status: action.data.status,
        //             title: action.data.title,
        //         }
        //     }
        // case ActionTypes.SET_LOADING_WRAPPER:
        //     return {
        //         ...state,
        //         loadingWrapper: {
        //             status: action.data.status,
        //             title: action.data.title,
        //         }
        //     }
        // case ActionTypes.CONFIRM_ORDER:
        //     const {curOrder} = action.data;
        //     console.log('curOrder');
        //     console.log(curOrder);
        //     return {...state};
        // case ActionTypes.SET_MSG_DIALOG:
        //     return {
        //         ...state,
        //         msgDialog: {
        //             ...action.data,
        //         },
        //     };
        // case ActionTypes.SET_ADDS_SPEC_MEAL_DIALOG:
        //     return {
        //         ...state,
        //         addSpecMealDialog: {
        //             open: action.data.open,
        //             data: action.data.data,
        //         },
        //     };
        // case ActionTypes.SET_ADD_MEAL_DIALOG:
        //     return {
        //         ...state,
        //         addMealDialog: {
        //             open: action.data.open,
        //             data: action.data.data,
        //         },
        //     };
        default:
            return {...state};
    }
}

export default takeoutReducer;
