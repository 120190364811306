import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import {setAddMealDialog} from '../../../actions/dineIn';
import Typography from '@material-ui/core/Typography';
import lng from '../../../misc/lng';

const { chiLng } = lng;

const useStyles = makeStyles((theme) => {
    return {
        wrapper: {
            width: '100%',
        },
        optWrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingTop: '10px',
            backgroundColor: 'white',
        },
        title: {
            paddingLeft: '10px',
            paddingTop: '30px',
            paddingBottom: '10px'
        },
        optBox: {
            backgroundColor: '#666666',
            color: 'white',
            padding: '5px',
            padding: '10px',
            margin: '5px',
            borderRadius: '10px',
            // border: '4px solid white',
        },
        optBoxSelected: {
            // backgroundColor: '#666666',
            backgroundColor: 'rgb(47 58 50)',
            color: 'white',
            padding: '10px',
            margin: '5px',
            borderRadius: '10px',
            // border: '4px solid black',
        },
        titleWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            marginRight: '20px',
        },
        subTitle: {
            opacity: '0.7',
        },
    }
});

// Provide layout for 1st and 2nd menu
const SimpleOptionList = (props) => {
    const classes = useStyles(props);
    const {simpleOptionList} = props;
    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant='h5'>
                    {chiLng.simpleOptTitle}
                </Typography>
                <Typography className={classes.subTitle} variant='subtitle2'>
                    {chiLng.notNecessaryToChoose}
                </Typography>
            </div>
            <div className={classes.optWrapper}>
                {
                    simpleOptionList.map(
                        (opt, idx) => {
                            return (
                                <div
                                    className={opt.selected ? classes.optBoxSelected : classes.optBox}
                                    onClick={() => {props.handleClickSimpleOpt(opt)}}
                                    key={idx}
                                >
                                    {opt.optionName}
                                </div>
                            );
                        }
                    )
                }
            </div>
        </div>
    );
};

export default SimpleOptionList;