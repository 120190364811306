import { combineReducers } from 'redux';
import productsReducer, * as fromProduct from './product';
import ordersReducer, * as fromOrder from './order';
import commonReducer, * as fromCommon from './common';
import openTblReducer, * as fromTable from './table';
import dineInReducer, * as fromDineIn from './dineIn';
import menuReducer, * as fromMenu from './menu';
import takeoutReducer, * as fromTakeout from './takeout';

// Combine all reducers as one and export
const rootReducer = combineReducers({
    productsReducer,
    // ordersReducer,
    commonReducer,
    // openTblReducer,
    dineInReducer,
    takeoutReducer,
    menuReducer
});

export default rootReducer;