import React, { useEffect, useState } from 'react';
import { cloneDeep, filter, get } from 'lodash';
import { CssBaseline } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import OnlineOrderMonitor from '../components/Admin/OnlineOrderMonitor'
import LocalOrderMonitor from '../components/Admin/LocalOrderMonitor'
import { defaultVal } from '../misc/envConst';
import lng from '../misc/lng';
import { BlackMesa } from 'mdi-material-ui';
import AllOrderMonitor from '../components/Admin/AllOrderMonitor';
import envConst from '../misc/envConst';


const useStyles = makeStyles((theme) => {
    return {
        btn: {
            fontSize: '1.5rem'
        },
        dialogContent: {
            width: '400px'
        },
        typoRoot: {
            color: theme.palette.allDay.main
        },
        root: {
            flexGrow: 1
        },
        tabWrapper: {
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '10px',
            marginBottom: '10px'
        },
        tabTitle: {
            padding: '10px',
        },
        tabTitleSelected: {
            padding: '10px',
            color: 'white',
            backgroundColor: 'black',
        }
    }
})

const AdminPortal = () => {
    const classes = useStyles();
    const commonReducer = useSelector(state => state.commonReducer);
    const aSocket = commonReducer.ws;
    const [mergedPaymentCompletedOrders, setMergedPaymentCompletedOrders] = useState([]);

    const [realTimeOrders, setRealTimeOrders] = useState({
        paymentCompletedOrders: [],
        readyToPickUpOrders: [],
        completedOrders: []
    });
    const [localOrders, setLocalOrders] = useState({
        paymentCompletedOrders: [],
        readyToPickUpOrders: [],
        completedOrders: []
    });

    const [dialog, setDialog] = useState({
        open: false,
        orderId: null,
        orderNumber: null,
        type: '',
        handleConfirm: () => { },
        handleClose: () => { },
    });

    const [status, setStatus] = useState('loading'); // loading, success, error
    const [tab, setTab] = useState(0); // 0, 1
    const [helperText, setHelperText] = useState('');
    // const ONLINE_ORDER_URL = `https://${process.env.REACT_APP_DEV_URL}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/online-order`;
    // const SET_ONLINE_ORDER_URL = `https://${process.env.REACT_APP_DEV_URL}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/confirm-online-order`;
    // const SET_LOCAL_ORDER_URL = `https://${process.env.REACT_APP_DEV_URL}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/confirm-local-order`;
    // const GET_LOCAL_ORDER_URL = `https://${process.env.REACT_APP_DEV_URL}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/local-order`;
    // const ONLINE_ORDER_URL = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/online-order`;
    // const SET_ONLINE_ORDER_URL = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/confirm-online-order`;
    // const SET_LOCAL_ORDER_URL = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/confirm-local-order`;
    // const GET_LOCAL_ORDER_URL = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/local-order`;

    const {ONLINE_ORDER_URL, SET_ONLINE_ORDER_URL, SET_LOCAL_ORDER_URL, GET_LOCAL_ORDER_URL} = envConst;

    const fetchInitialState = async () => {
        try {
            // update this call after online ordering completed
            const allOrderRet = await axios.get(ONLINE_ORDER_URL);
            // console.log('allOrderRet');
            // console.log(allOrderRet);
            if (!allOrderRet || !allOrderRet.data || !allOrderRet.data.success) {
                throw lng.chiLng.errDoOrderData;
            }

            const allOrderDataArr = allOrderRet.data.orders;

            if (allOrderDataArr.length === 0) {
                setRealTimeOrders({
                    paymentCompletedOrders: [],
                    readyToPickUpOrders: [],
                    completedOrders: []
                });
            }

            // filter only status with preparing
            const paymentCompletedOrders = filter(allOrderDataArr, (order) => {
                if (order.status === 'paymentCompleted') {
                    return true;
                }
                return false;
            });
            // console.log('paymentCompletedOrders')
            // console.log(paymentCompletedOrders)
            const readyToPickUpOrders = filter(allOrderDataArr, (order) => {
                if (order.status === 'waitingForPickup') {
                    return true;
                }
                return false;
            });
            // console.log('readyToPickUpOrders')
            // console.log(readyToPickUpOrders)
            const completedOrders = filter(allOrderDataArr, (order) => {
                if (order.status === 'orderCompleted') {
                    return true;
                }
                return false;
            });

            setRealTimeOrders(
                {
                    paymentCompletedOrders,
                    readyToPickUpOrders,
                    completedOrders
                }
            );

            // fetch local orders
            const localOrderRet = await axios.get(GET_LOCAL_ORDER_URL);
            console.log('localOrderRet');
            console.log(localOrderRet);

            if (!localOrderRet || !localOrderRet.data || !localOrderRet.data.success) {
                throw lng.chiLng.errDoOrderData;
            }

            const localOrderDataArr = localOrderRet.data.orders;

            if (localOrderDataArr.length === 0) {
                setLocalOrders({
                    paymentCompletedOrders: [],
                    readyToPickUpOrders: [],
                    completedOrders: []
                });
            }

            // filter only status with preparing
            const localPaymentCompletedOrders = filter(localOrderDataArr, (order) => {
                if (order.status === 'paymentCompleted') {
                    return true;
                }
                return false;
            });
            // console.log('paymentCompletedOrders')
            // console.log(paymentCompletedOrders)
            const localReadyToPickUpOrders = filter(localOrderDataArr, (order) => {
                if (order.status === 'waitingForPickup') {
                    return true;
                }
                return false;
            });
            // console.log('readyToPickUpOrders')
            // console.log(readyToPickUpOrders)
            const localCompletedOrders = filter(localOrderDataArr, (order) => {
                if (order.status === 'orderCompleted') {
                    return true;
                }
                return false;
            });

            setLocalOrders(
                {
                    paymentCompletedOrders: localPaymentCompletedOrders,
                    readyToPickUpOrders: localReadyToPickUpOrders,
                    completedOrders: localCompletedOrders
                }
            );

            setStatus('success');
        } catch (e) {
            console.error(e);
            setRealTimeOrders([]);
        }
    };

    const handleClose = () => {
        setDialog({
            open: false,
            orderId: null,
            orderNumber: null,
            type: '',
            handleConfirm: () => { },
            handleClose: () => { },
        });
    };

    const handleConfirm = async (e, targetId, type) => {
        if (type === 'online') {
            await axios.post(SET_ONLINE_ORDER_URL, { idList: [targetId] });
            await fetchInitialState();
            handleClose();
        } else {
            await axios.post(SET_LOCAL_ORDER_URL, { idList: [targetId] });
            await fetchInitialState();
            handleClose();
        }
    };

    const handleMonitorGridClick = (id, orderNumber, type) => {
        handleConfirm(null, id, type);
        // setDialog({
        //     open: true,
        //     orderId: id,
        //     type,
        //     orderNumber: orderNumber,
        //     handleConfirm: (e) => { handleConfirm(e, id, type); },
        //     handleClose: handleClose,
        // });
    };

    useEffect(() => {
        // fetch database once
        fetchInitialState();
    }, []);

    useEffect(() => {
        console.log('aSocket')
        if (aSocket) {
            // socket io to receive online ordering for updating the monitor items
            aSocket.on('localOrderAdded', (data) => {
                console.log('localOrderAdded');
                console.log(data);
                if (data.success) {
                    const newLocalOrders = data.orders;
                    console.log('newLocalOrders')
                    console.log(newLocalOrders)
                    const paymentCompletedOrders = newLocalOrders.filter(
                        (order) => {
                            if (order.status === 'paymentCompleted') {
                                return true;
                            }
                            return false;
                        }
                    );
                    const readyToPickUpOrders = newLocalOrders.filter(
                        (order) => {
                            if (order.status === 'waitingForPickup') {
                                return true;
                            }
                            return false;
                        }
                    );
                    console.log('paymentCompletedOrders')
                    console.log(paymentCompletedOrders)
                    console.log('readyToPickUpOrders')
                    console.log(readyToPickUpOrders)
                    setLocalOrders({
                        ...localOrders,
                        paymentCompletedOrders,
                        readyToPickUpOrders,
                    });
                }
            });

            aSocket.on('onlineOrderAdded', (data) => {
                console.log('onlineOrderAdded');
                console.log(data);
                if (data.success) {
                    const newLocalOrders = data.orders;
                    console.log('newLocalOrders')
                    console.log(newLocalOrders)
                    const paymentCompletedOrders = newLocalOrders.filter(
                        (order) => {
                            if (order.status === 'paymentCompleted') {
                                return true;
                            }
                            return false;
                        }
                    );
                    const readyToPickUpOrders = newLocalOrders.filter(
                        (order) => {
                            if (order.status === 'waitingForPickup') {
                                return true;
                            }
                            return false;
                        }
                    );
                    console.log('paymentCompletedOrders')
                    console.log(paymentCompletedOrders)
                    console.log('readyToPickUpOrders')
                    console.log(readyToPickUpOrders)
                    setRealTimeOrders({
                        ...localOrders,
                        paymentCompletedOrders,
                        readyToPickUpOrders,
                    });
                }
            });

        }

        return () => {
            if (aSocket) {
                aSocket.off('onlineOrderRev');
            }
        }
    }, [aSocket]);

    // useEffect(() => {
    //     console.log('localOrders')
    //     console.log(localOrders.paymentCompletedOrders)
    //     const mergedOrders = [...localOrders.paymentCompletedOrders, ...realTimeOrders.paymentCompletedOrders]
    //         .sort((a, b) => new Date(a.orderTime) - new Date(b.orderTime));
    //     setMergedPaymentCompletedOrders(mergedOrders);
    // }, [localOrders, realTimeOrders]);

    useEffect(() => {
        console.log('localOrders')
        console.log(localOrders.paymentCompletedOrders)
        const localOrdersArray = localOrders.paymentCompletedOrders || [];
        const realTimeOrdersArray = realTimeOrders.paymentCompletedOrders || [];
        const mergedOrders = [...localOrdersArray, ...realTimeOrdersArray]
            .sort((a, b) => new Date(a.orderTime) - new Date(b.orderTime));
        setMergedPaymentCompletedOrders(mergedOrders);
    }, [localOrders, realTimeOrders]);

    useEffect(() => {
        console.log('mergedPaymentCompletedOrders')
        console.log(mergedPaymentCompletedOrders)
    }, [mergedPaymentCompletedOrders]);

    if (status === 'loading') {
        return (
            <div>Loading...</div>
        )
    }
    const getTabPanel = () => {
        if (tab === 0) {
            return (
                <AllOrderMonitor
                    paymentCompletedOrders={mergedPaymentCompletedOrders}
                    handleMonitorGridClick={handleMonitorGridClick}
                />
            );

        } else if (tab === 1) {
            return (
                (<OnlineOrderMonitor
                    realTimeOrders={realTimeOrders}
                    handleMonitorGridClick={handleMonitorGridClick}
                />)
            );
        } else if (tab === 2) {
            return (
                (<LocalOrderMonitor
                    realTimeOrders={localOrders}
                    handleMonitorGridClick={handleMonitorGridClick}
                />)
            );
        }
    };

    return (
        <React.Fragment>
            <Dialog
                open={dialog.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" disableTypography>
                    <Typography variant="h5">{lng.chiLng.confirmOrderReadyDialogTitle}</Typography>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Typography align='center' variant="h1">{dialog.orderNumber}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button classes={{ textSizeLarge: classes.btn }} size="large" onClick={dialog.handleClose} color="primary">
                        {lng.chiLng.cancel}
                    </Button>
                    <Button classes={{ textSizeLarge: classes.btn }} size="large" onClick={dialog.handleConfirm} color="primary">
                        {lng.chiLng.confirm}
                    </Button>
                </DialogActions>
            </Dialog>
            <CssBaseline />
            {/* <Grid className={classes.root} container alignItems="center" justifyContent="center">
                <Grid item>
                    <img
                        src={process.env.PUBLIC_URL + '/assets/logo_fb.jpg'}
                        alt="allday"
                        style={{
                            marginRight: '8px',
                            borderRadius: '50%',
                            width: '60px'
                        }}
                    />
                </Grid>
                <Grid item>
                    <Typography classes={{ root: classes.typoRoot }} variant="h3"><b>{lng.chiLng.kitchenMonTitle}</b></Typography>
                </Grid>
            </Grid> */}
            {/* <div className={classes.tabWrapper}>
            <Typography
                    onClick={() => { setTab(0) }}
                    classes={{ root: tab === 0 ? classes.tabTitleSelected : classes.tabTitle }}
                    variant="h4"
                >
                    {lng.chiLng.kitchenMonAll}
                </Typography>
                <Typography
                    onClick={() => { setTab(1) }}
                    classes={{ root: tab === 1 ? classes.tabTitleSelected : classes.tabTitle }}
                    variant="h4">
                    {lng.chiLng.kitchenMonOnlineOrder}
                </Typography>
                <Typography
                    onClick={() => { setTab(2) }}
                    classes={{ root: tab === 2 ? classes.tabTitleSelected : classes.tabTitle }}
                    variant="h4"
                >
                    {lng.chiLng.kitchenMonlocalOrder}
                </Typography>
            </div> */}
            {getTabPanel()}
        </React.Fragment>
    );
};

export default AdminPortal;