export const getAllSpecPrice = (list) => {
    let price = 0;
    list.forEach(
        (l) => {
            l.specOptions.forEach(
                (opt) => {
                    price += parseInt(opt.specPrice);
                }
            )
        }
    );
    return price;
};

export const getSpecMealPrice = (meal) => {
    console.log('meal');
    console.log(meal);
    const specPrice = getAllSpecPrice(meal.specList);
    const totalPrice = specPrice + meal.price;
    return totalPrice;
}
export const calculateTotalPay = (productData) => {
    const totalPay = productData.reduce((tmpCalculateSum, productDatum) => {
        let currentProductEarned = productDatum.price * productDatum.qty;
        if (productDatum.hasMenuSpec === 'true') {
            let totalPrice = 0;
            productDatum.productSpec.forEach(
                (spec) => {
                    totalPrice += spec.priceForOne * spec.qty;
                }
            );
            currentProductEarned = totalPrice;
        }
        return tmpCalculateSum + currentProductEarned;
    }, 0);
    return totalPay;
}

export const getProductCount = (productData, validOrders) => {
    const count = productData.reduce((tmpCalculateSum, productDatum) => {
        let currentProductEarned = productDatum.qty;
        if (productDatum.hasMenuSpec === 'true') {
            let totalCount = 0;
            productDatum.productSpec.forEach(
                (spec) => {
                    totalCount += spec.qty;
                }
            );
            currentProductEarned = totalCount;
        }
        return tmpCalculateSum + currentProductEarned;
    }, 0);
    const confirmedVoidOrderCount = validOrders
    .map(validOrder => validOrder.orderDetails)
    .flatMap(validOrder => validOrder)
    .reduce(
        (tmpCalculateSum, validOrder) => {
            let currentProductEarned = validOrder.qty;
            if (validOrder.hasMenuSpec === 'true') {
                let totalCount = 0;
                validOrder.productSpec.forEach(
                    (spec) => {
                        totalCount += spec.qty;
                    }
                );
                currentProductEarned = totalCount;
            }
            return tmpCalculateSum + currentProductEarned;
        }, 0
    );
    console.log('count')
    console.log(count)
    console.log('confirmedVoidOrderCount')
    console.log(confirmedVoidOrderCount)
    return count + confirmedVoidOrderCount;
}