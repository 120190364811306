const API_PREFIX = 'api';
const envConst = {
    API_DINE_IN: `https://${API_PREFIX}.${window.location.host}/DineInTable/dineIn`,
    API_ADD_ORDER: `https://${API_PREFIX}.${window.location.host}/DineInTable/addOrder`,
    API_GET_ENTIRE_MENU: `https://${API_PREFIX}.${window.location.host}/Restaurant/getEntireMenu`,
    WS_URL: `https://${API_PREFIX}.${window.location.host}`,
    SHOP_IMG_PATH: '../static/shop/img', // not used, just for record
    PROD_IMG_PATH: '../static/prod/img', // not used, just for record
    backendGeneralTimeout: 0, // no limitation for timeout period
    MAX_MEAL_ITEM: 999, // adjust no. of items user can add in menu 
    MAX_MEAL_QTY: 999, // adjust in reducer max qty to calculate order payment amount
    GET_MEAL_IMG_URL: `https://${API_PREFIX}.${window.location.host}/assets/meal`,
    GET_MENU_URL: `https://${API_PREFIX}.${window.location.host}/assets/menu`,
    API_PAY: `https://${API_PREFIX}.${window.location.host}/pay`,
    GET_ORDER_URL: `https://${API_PREFIX}.${window.location.host}/takeout/order`,
    DEFAULT_IMG_PATH: `https://${window.location.host}/assets/default-meal.jpg`,
    IMAGE_PATH: `https://${API_PREFIX}.${window.location.host}/mealImages/`,
    ONLINE_ORDER_URL: `https://${API_PREFIX}.${window.location.host}/online-order`,
    SET_ONLINE_ORDER_URL: `https://${API_PREFIX}.${window.location.host}/confirm-online-order`,
    SET_LOCAL_ORDER_URL: `https://${API_PREFIX}.${window.location.host}/confirm-local-order`,
    GET_LOCAL_ORDER_URL: `https://${API_PREFIX}.${window.location.host}/local-order`,
}

// default export
export default envConst;