import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import lng from '../../../misc/lng';
import {getProductCount} from '../../../utils/meal';


const {chiLng} = lng;

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        left: '0',
        height: '70px',
        width: '100%',
        backgroundColor: 'white',
        boxShadow: '0px 0 5px rgba(0, 0, 0, 0.8)',
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
        transition: 'bottom 0.5s',
    },
    openCart: {
        bottom: '0',
    },
    closeCart: {
        bottom: '-70px',
    },
    wrapper: {
        width: '95%',
        height: '40px',
        borderRadius: '5px',
        backgroundColor: '#666666',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        lineHeight: '34px'
    },
    count: {
        // height: '34px',
        // backgroundColor: 'white',
        // borderRadius: '5px',
        // marginLeft: '10px',
        // color: '#666666',
        // fontWeight: '800',
        // lineHeight: '34px'
        color: 'white',
        marginLeft: '10px',
        fontSize: '20px',
    },
    price: {
        color: 'white',
        marginRight: '10px',
        fontSize: '20px',
    },
    middleLabel: {
        color: 'white',
        fontSize: '20px',
    }
  }));


export default (props) => {
    const classes = useStyles();
    const {
        validOrders,
    } = useSelector((store) => store.dineInReducer);
    const {
        productData, productSummary
    } = useSelector((store) => store.productsReducer);
    const {priceAfterDiscount} = productSummary;
    const count = getProductCount(productData, validOrders);
    const getCartStyle = () => {
        if (productData.length > 0 || validOrders.length > 0) {
            return classes.openCart;
        }
        return classes.closeCart;
    }
    return(
        <div className={`${classes.root} ${getCartStyle()}`}>
            <div className={classes.wrapper}>
                {/* <div className={classes.count}>{priceAfterDiscount}</div> */}
                <Typography className={classes.count} variant='h4'>
                    {count}
                </Typography>
                <Typography className={classes.count} variant='h4'>
                    {chiLng.checkShoppingCart}
                </Typography>
                <Typography className={classes.price} variant='h4'>
                    {`$${priceAfterDiscount}`}
                </Typography>
            </div>
        </div>
    );
};