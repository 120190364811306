import ProductSpec from './ProductSpec';

class OrderDetail {
    constructor(data = {}) {
        this.mealCategoryId = data.mealCategoryId || null;
        this.mealSubCategoryId = data.mealSubCategoryId || null;
        this.mealId = data.mealId || null;
        this.name = data.name || null;
        this.additionalOptionCategory = data.additionalOptionCategory || null;
        this.qty = data.qty || 0;
        this.price = data.price || 0;
        this.weekday = data.weekday || null;
        this.img = data.img || null;
        this.tag = data.tag || null;
        this.hasMenuSpec = data.hasMenuSpec || null;
        this.printerName = data.printerName || null;
        this.simpleOptions = data.simpleOptions || null;
        this.productSpec = data.productSpec ? data.productSpec.map(spec => new ProductSpec(spec, this.price)) : [];
    }

    serialize() {
        return {
            mealCategoryId: this.mealCategoryId,
            mealSubCategoryId: this.mealSubCategoryId,
            mealId: this.mealId,
            name: this.name,
            additionalOptionCategory: this.additionalOptionCategory,
            qty: this.qty,
            price: this.price,
            weekday: this.weekday,
            img: this.img,
            tag: this.tag,
            hasMenuSpec: this.hasMenuSpec,
            printerName: this.printerName,
            simpleOptions: this.simpleOptions,
            productSpec: this.productSpec.map(spec => spec.serialize())
        };
    }

    getTotalPrice() {
        if (this.hasMenuSpec === 'false') {
            return this.qty * this.price;
        } else if (this.productSpec.length === 0) {
            return this.price;
        }
        return this.productSpec.reduce((acc, cur) => {
            return acc + cur.getTotalPrice();
        }, 0);
    }


    getPrintStringArray(withStyle = false) {
        if (this.hasMenuSpec === 'true') {
            return this.productSpec.map(spec => spec.getPrintString(this.name));
        }
        return [`${this.qty} X ${this.name}`];
    }

}
export default OrderDetail;