
import Spec from './Spec';

class ProductSpec {
    constructor(data = {}, unitPrice = 0) {
        this.unitPrice = unitPrice;
        this.priceForOne = data.priceForOne || 0;
        this.qty = data.qty || 0;
        this.spec = data.spec ? data.spec.map(s => new Spec(s)) : [];
    }

    serialize() {
        return {
            priceForOne: this.priceForOne,
            qty: this.qty,
            spec: this.spec.map(s => s.serialize())
        };
    }

    getPriceForOne() {
        return this.spec.reduce((acc, cur) => {
            return acc + cur.getTotalPrice();
        }, 0);
    }

    getTotalPrice() {
        return this.qty * (this.spec.reduce((acc, cur) => {
            return acc + cur.getTotalPrice();
        }, 0) + this.unitPrice);
    }

    getPrintString(name) {
        return `${this.qty} x ${name} (${this.spec.map(s => s.getPrintString()).join(' ')})`;
    }
}

export default ProductSpec;