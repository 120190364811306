import * as ActionTypes from '../actions';
import {envConst} from '../misc/lng';
import {knapsack, getKSResult} from '../utils/knapsack';
import {calculateTotalPay} from '../utils/meal';
import {filterVoidOrders} from '../utils/orderFilterFunc';

// State object design
// {
//      [productId]: {
//          productName: '',
//          productImg: '',
//          unitPrice: xx,
//          unitQty: xx,
//          unitStock: xx
//      }
// }
//

const initialStateObj = {
    productData: [],
    productSummary: {
        discountPrice: 0,
        priceAfterDiscount: 0,
        tagSetDiscountList: [],
        totalPrice: 0,
    },
};

const productsReducer = (state = initialStateObj, action) => {
    switch(action.type) {
        case ActionTypes.SET_SHOPPING_CART: {
            return {
                ...state,
                productData: action.data.order,
            };
        }
        case ActionTypes.UPDATE_CART_ADD_SEPARATELY: {
            console.log('UPDATE_CART_ADD_SEPARATELY');
            console.log(action);
            const inputProductData = action.data;
            const {productData} = state;
            
            return {
                ...state,
                productData: [...productData, inputProductData]
            };
        }
        case ActionTypes.UPDATE_PRODUCT_QTY: {
            const {productData} = state;
            const {qty, productIdx, specIdx} = action.data;
            return {
                ...state,
                productData: productData.map(
                    (product, pIdx) => {
                        if (pIdx === productIdx) {
                            if (product.hasMenuSpec === 'true') {
                                return {
                                    ...product,
                                    productSpec: product.productSpec.map(
                                        (spec, sIdx) => {
                                            if (sIdx === specIdx) {
                                                return {
                                                    ...spec,
                                                    qty: qty,
                                                };
                                            }
                                            return spec;
                                        }
                                    ),
                                };
                            }
                            return {
                                ...product,
                                qty: qty
                            }
                        }
                        return product;
                    }
                ),
            };
        }
        case ActionTypes.PARSE_PRODUCT_DATA: {
            const {productData} = state;
            const inputProductData = productData.filter(
                (product) => {
                    let shouldStay = true;

                    if (product.hasMenuSpec === 'true') {
                        const filteredSpecList = product.productSpec.filter(
                            (spec) => {
                                if (spec.qty <= 0) {
                                    return false;
                                }
                                return true;
                            }
                        );
                        if (filteredSpecList.length <= 0) {
                            shouldStay = false;
                        }
                    } else {
                        if (product.qty <= 0) {
                            shouldStay = false;
                        }
                    }

                    return shouldStay;
                }
            );
            return {
                ...state,
                productData: inputProductData,
            };
        }
        case ActionTypes.UPDATE_DISCOUNT: {
            console.log('UPDATE_DISCOUNT');
            
            const {productData} = state;

            const {tagSetList, confirmedOrders} = action.data;
            let filterdConfirmedOrders = [];
            if (confirmedOrders.length > 0) {
                filterdConfirmedOrders = filterVoidOrders(confirmedOrders).map(
                    (confirmedOrder) => {
                        return [...confirmedOrder.orderDetails];
                    }
                ).flatMap(orderDetail => orderDetail)
            }
            const totalOrder = [...productData, ...filterdConfirmedOrders];
            console.log('totalOrder')
            console.log(totalOrder)
            const totalPrice = calculateTotalPay(totalOrder);
            // console.log('tagSetList');
            // console.log(tagSetList);
    
            // only count with enable tagSet
            const inputSetList = tagSetList.filter(t => t.enable);
    
            // only count with non-default meal
            let inputProductData = totalOrder.filter(p => p.tag !== 'default' && p.disableTagSet !== true);
            inputProductData = inputProductData.filter(
                (product) => {
                    let shouldStay = true;

                    if (product.hasMenuSpec === 'true') {
                        const filteredSpecList = product.productSpec.filter(
                            (spec) => {
                                if (spec.qty <= 0) {
                                    return false;
                                }
                                return true;
                            }
                        );
                        if (filteredSpecList.length <= 0) {
                            shouldStay = false;
                        }
                    } else {
                        if (product.qty <= 0) {
                            shouldStay = false;
                        }
                    }

                    return shouldStay;
                }
            );


            console.log('kennny-debug');
            console.log(inputProductData);
            // if there are no tagSet or product with tag, no need to cal discount
            if (inputSetList.length < 1 || inputProductData.length < 1) {
    
                return {
                    ...state,
                    productSummary: {
                        totalPrice,
                        priceAfterDiscount: totalPrice,
                        discountPrice: 0,
                        tagSetDiscountList: [],
                    },
                };
            }
    
            // only tag id is need to cal
            const inputProductTagList = [];
    
            console.log('inputProductData');
            console.log(inputProductData);
    
            inputProductData.forEach(
                (product) => {
                    if (product.hasMenuSpec === 'true') {
                        const qty = product.productSpec.reduce(
                            (previousValue, currentValue) => {
                                return previousValue + currentValue.qty;
                            }, 0
                        );
                        [...Array(qty)].forEach(
                            () => {
                                inputProductTagList.push(product.tag);
                            }
                        )
                    } else {
                        [...Array(product.qty)].forEach(
                            () => {
                                inputProductTagList.push(product.tag);
                            }
                        )
                    }
                }
            );
    
            // console.log('inputProductTagList');
            // console.log(inputProductTagList);
    
            // const result = knapsack(inputProductTagList, inputSetList);
            const discountResult = getKSResult(inputProductData, inputSetList);
            // console.log('result');
            // console.log(result);
            
            // const discountResult = {
            //     priceReduced: 0,
            //     accumulateList: [],
            // }
    
            console.log('discountResult');
            console.log(discountResult);
            
            const priceAfterDiscount = totalPrice - discountResult.priceReduced;
            
            const discountMap = {};
    
    
            //merge discount item with same id
            discountResult.accumulateList.forEach(
                (discount) => {
                    if (discountMap[discount.id]) {
                        discountMap[discount.id].qty += 1;
                    } else {
                        discountMap[discount.id] = {
                            ...discount,
                            qty: 1,
                        }
                    }
                }
            );
    
            const productSummary = {
                priceAfterDiscount,
                totalPrice,
                discountPrice: discountResult.priceReduced,
                tagSetDiscountList: Object.keys(discountMap).map(d => discountMap[d]),
            };
    
            return {
                ...state,
                productSummary,
            };
        }
        default:
            return {
                ...state,
            }
    }
}

export default productsReducer;
