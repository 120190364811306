class SpecOption {
    constructor(data = {}) {
        this.specId = data.specId || null;
        this.specName = data.specName || null;
        this.specStock = data.specStock || 0;
        this.specPrice = data.specPrice || 0;
        this.printerName = data.printerName || null;
        this.specQty = data.specQty || 0;
        this.selected = data.selected || false;
    }

    getTotalPrice() {
        return this.specPrice;
    }
    
    serialize() {
        return {
            specId: this.specId,
            specName: this.specName,
            specStock: this.specStock,
            specPrice: this.specPrice,
            printerName: this.printerName,
            selected: this.selected
        };
    }
}

export default SpecOption;