const API_REST_PORT = 4002;
const API = 'api';
const envConst = {
    API_DINE_IN: `https://${window.location.hostname}:${API_REST_PORT}/DineInTable/dineIn`,
    API_ADD_ORDER: `https://${window.location.hostname}:${API_REST_PORT}/DineInTable/addOrder`,
    API_GET_ENTIRE_MENU: `https://${window.location.hostname}:${API_REST_PORT}/Restaurant/getEntireMenu`,
    // API_GET_MEAL: `http://${window.location.hostname}:${API_REST_PORT}/MealCategories/Meals`,
    // API_GET_MEAL_MAIN: `http://${window.location.hostname}:${API_REST_PORT}/MealCategories/MainCat`,
    // API_GET_MEAL_SUB: `http://${window.location.hostname}:${API_REST_PORT}/MealCategories/SubCat`,
    // API_GET_MEAL_SPEC: `http://${window.location.hostname}:${API_REST_PORT}/MealSpec`,
    // API_GET_SYS_PARA: `http://${window.location.hostname}:${API_REST_PORT}/${API}/SystemControlParameters`,
    WS_URL: `https://${process.env.REACT_APP_DEV_URL}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}`,
    SHOP_IMG_PATH: '../static/shop/img', // not used, just for record
    PROD_IMG_PATH: '../static/prod/img', // not used, just for record
    backendGeneralTimeout: 0, // no limitation for timeout period
    MAX_MEAL_ITEM: 999, // adjust no. of items user can add in menu 
    MAX_MEAL_QTY: 999, // adjust in reducer max qty to calculate order payment amount
    GET_MEAL_IMG_URL: './assets/meal', // we cannot point to the static assets at src folder as they are minimized, put assests in public folder
    GET_MENU_URL: './assets/menu', // we cannot point to the static assets at src folder as they are minimized, put assests in public folder
    API_PAY: `https://${window.location.hostname}:${API_REST_PORT}/pay`,
    GET_ORDER_URL: `https://${window.location.hostname}:${API_REST_PORT}/takeout/order`,
    DEFAULT_IMG_PATH: `/assets/default-meal.jpg`,
    IMAGE_PATH: `https://${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/mealImages/`,
    ONLINE_ORDER_URL: `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/online-order`,
    SET_ONLINE_ORDER_URL: `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/confirm-online-order`,
    SET_LOCAL_ORDER_URL: `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/confirm-local-order`,
    GET_LOCAL_ORDER_URL: `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CLOUD_DEV_SERVER_PORT}/local-order`,
}

// default export
export default envConst;