const checkIfContainSet = (list, set) => {
    // console.log('set');
    // console.log(set);
    // console.log('list');
    // console.log(list);
    const tmp = [...set];
    const result = list.filter(
        (listItem) => {
            const indexOfItem = tmp.indexOf(listItem);
            if (indexOfItem >= 0) {
                tmp.splice(indexOfItem, 1);
                return false;
            }
            return true;
        }
    );
    return {
        isContain: tmp.length < 1,
        result,
    }
};

const knapsack = (custList, inputSetList, priceReduced, accumulateList) => {
    // console.log('knapsack--------------------------------------------------------------------------------');
    // console.log('custList');
    // console.log(custList);
    // console.log('inputSetList');
    // console.log(inputSetList);
    // console.log('priceReduced');
    // console.log(priceReduced);
    // console.log('accumulateList');
    // console.log(accumulateList);
    // console.log('knapsack--------------------------------------------------------------------------------');
    const resultList = inputSetList.map(
        (inputSet) => {
            return checkIfContainSet(custList, inputSet.tagList);
        }
    );
    // console.log('resultList');
    // console.log(resultList);
    const acceptList = resultList.filter(res => res.isContain);

    // console.log('acceptList');
    // console.log(acceptList);
    const acceptInputSetList = inputSetList.filter(
        (inputSet, idx) => {
            return resultList[idx].isContain;
        }
    )
    // console.log('acceptInputSetList');
    // console.log(acceptInputSetList);
    if (acceptList.length <= 0) {
        // return priceReduced;
        return {
            priceReduced,
            accumulateList,
        };
    }
    const recusiveList = acceptList.map(
        (acceptItem, idx) => {
            return knapsack(acceptItem.result, acceptInputSetList, priceReduced + Math.abs(acceptInputSetList[idx].price), [...accumulateList, acceptInputSetList[idx]]);
        }
    );
    // return Math.max(
    //     ...recusiveList
    // );
    return recusiveList.reduce(
        (previousValue, currentValue) => {
            if (previousValue.priceReduced > currentValue.priceReduced) {
                return previousValue;
            }
            return currentValue;
        },
        {
            priceReduced: 0,
            accumulateList: [],
        }
    );
};


const getProductTagMap = (inputProductData) => {
    const res = inputProductData.map(
        (productData) => {
            if (productData.hasMenuSpec === 'true') {
                const qty = productData.productSpec.reduce(
                    (previousValue, currentValue) => {
                        return previousValue + currentValue.qty;
                    }, 0
                );
                return {
                    tag: productData.tag,
                    qty: qty
                };
            } else {
                return {
                    tag: productData.tag,
                    qty: productData.qty
                };
            }
        }
    ).reduce(
        (previousValue, currentValue) => {
            if (previousValue[currentValue.tag]) {
                return {
                    ...previousValue,
                    [currentValue.tag]: previousValue[currentValue.tag] + currentValue.qty,
                };
            }
            return {
                ...previousValue,
                [currentValue.tag]: currentValue.qty,
            };
        }, {}
    );
    
    return res;
}

const getSeqMap = (map) => {
    const seqMap = {};
    Object.keys(map).forEach(
        (tag, idx) => {
            seqMap[tag] = idx;
        }
    );
    return seqMap;
}

const applySet = (input, set) => {
    // console.log('set');
    // console.log(set);
    // console.log('list');
    // console.log(list);
    let canApply = true;
    const result = {...input};
    set.forEach(
        (tag) => {
            if (!result[tag] || (result[tag] - 1) < 0) canApply = false;
            else result[tag] -= 1;
        }
    );
    return {
        canApply,
        result,
    }
};

const getDPStr = (seqList, input) => {
    return seqList.map(
        (tag) => {
            return input[tag];
        }
    ).join('-');
}

const getKSResult = (inputProductData, inputSet) => {
    // console.log('knapsack--------------------------------------------------------------------------------');
    // console.log('inputProductData');
    // console.log(inputProductData);
    // console.log('inputSet');
    // console.log(inputSet);
    // console.log('knapsack--------------------------------------------------------------------------------');

    const inputObj = getProductTagMap(inputProductData);
    const seqList = Object.keys(inputObj);
    const dp = {};

    // console.log('inputObj');
    // console.log(inputObj);
    // console.log('seqList');
    // console.log(seqList);

    let count = 0;

    const ks = (custList, inputSetList, priceReduced, accumulateList) => {
        count++;
        const dpStr = getDPStr(seqList, custList);

        if (dp[dpStr]) return dp[dpStr];

        const resultList = inputSetList.map(
            (inputSet) => {
                return applySet(custList, inputSet.tagList);
            }
        );

        const acceptList = resultList.filter(res => res.canApply);

        const acceptInputSetList = inputSetList.filter(
            (inputSet, idx) => {
                return resultList[idx].canApply;
            }
        )

        if (acceptList.length <= 0) {
            // return priceReduced;
            return {
                priceReduced,
                accumulateList,
            };
        }

        const recusiveList = acceptList.map(
            (acceptItem, idx) => {
                return ks(
                    acceptItem.result,
                    acceptInputSetList,
                    priceReduced + Math.abs(acceptInputSetList[idx].price),
                    [...accumulateList, acceptInputSetList[idx]]
                );
            }
        );

        const result = recusiveList.reduce(
            (previousValue, currentValue) => {
                if (previousValue.priceReduced > currentValue.priceReduced) {
                    return previousValue;
                }
                return currentValue;
            },
            {
                priceReduced: 0,
                accumulateList: [],
            }
        );
        dp[dpStr] = result;
        return result;
    }

    const ksRes = ks(inputObj, inputSet, 0, []);
    // console.log('dp');
    // console.log(dp);
    // console.log(count);
    return ksRes;
};

export { knapsack, getKSResult };