import React, { useEffect } from 'react';
import io from 'socket.io-client';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createTheme } from '@material-ui/core/styles';
// import DineInPage from './containers/DineInPage';
import ClientDineIn from './containers/ClientDineIn';
import AdminOrderMonitor from './containers/AdminPortal';
import {saveWsObj} from "./actions";
import ClientTakeout from './containers/ClientTakeout';
import takeoutClientOrderPage from './containers/takeoutClientOrderPage';
import 'typeface-roboto';
import './App.css';
import envConst from './misc/envConst';

// define global site main and secondary color
const themeStyle = {
  palette: {
    primary: {
      main: '#e65100',
      custlight: '#ffb74d',
      light: 'rgb(242, 153, 74, 0.8)',
      takeAway: 'rgb(79, 79, 79)',
      splitTbl: 'rgb(111, 207, 150)'
    },
    secondary: {
      main: '#191919',
      main2: '#212121',
      light: '#484848'
    },
    allDay: {
      main: '#413D3E'
    }
  },
  fonts: {
    commonFontSize: {
      fontSize: '2.2rem'
    },
    systemTitle: {
      fontSize: '2rem',
      fontWeight: 'bold',
      display: 'flex',
    },
  }
};

const theme = createTheme(themeStyle);

// main entrance
const App = (props) => {
  useEffect(() => {
    if (window.location.href.includes("adminOrderMonitor")) {
      console.log("The URL contains 'adminOrderMonitor'");
      // connect to cloud socket io server 
      console.log(process.env);
      const ioURL = envConst.WS_URL;
      const aSocket = io(ioURL, {
        withCredentials: true,
        rejectUnauthorized: false,
        auth: {
            identity: "orderMonitor",
        },
      });
      // save to redux store
      console.log(aSocket)
      props.saveWsObj(aSocket);
  
      return () => {
        // clean up
        aSocket.disconnect();
      }
  }
  },[]);

  return (
      <ThemeProvider theme={theme}>
          <div className="App">
              <Router>
                  {/* <Route path="/dineIn" component={DineInPage} /> */}
                  <Route path="/dineIn" component={ClientDineIn} />
                  <Route path="/adminOrderMonitor" component={AdminOrderMonitor} />
                  <Route exact path="/takeout" component={ClientTakeout} />
                  <Route path="/takeout/successOrder" component={takeoutClientOrderPage} />
              </Router>
          </div>
      </ThemeProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    view: state.commonReducer.view
  }
}

export default compose(
    connect(mapStateToProps, {
        saveWsObj
    })
)(App);