import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import lng from '../../../misc/lng';


const {chiLng} = lng;

const useStyles = makeStyles(() => ({
    root: {

    },
    mealWrapper: {
        backgroundColor: 'white',
        borderTop: '1.5px solid #efd8d8',
        borderBottom: '1.5px solid #efd8d8',
        marginBottom: '30px',
    },
    countWrapper: {
        marginTop: '10px',
        backgroundColor: 'white',
        borderTop: '1.5px solid #efd8d8',
        justifyContent: 'space-between',
        borderBottom: '1.5px solid #efd8d8',
        display: 'flex',
        padding: '10px 20px',
    },
    itemTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        // paddingBottom: '10px',
    },
    itemWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '10px',
        marginTop: '10px',
    },
    itemInLeft: {
        padding: '20px',
    },
    itemInRight: {
        padding: '20px',
        // borderBottom: '1px solid #4f4f4f',
    },
    itemTitleInMiddle: {
        flex: 1,
    },
    itemInMiddle: {
        flex: 1,
        // borderBottom: '1px solid #4f4f4f',
    },
    orderItemContainer:{
        width:"100%",
        // paddingBottom: "20px",
        // marginTop: '20px'
    },
    orderHeaderWrapper: {
        width: '100%',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
    orderRemoveWrapper: {
        width: '50px',
    },
    orderQtyWrapper: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        width: '50px',
    },
    orderTitleWrapper: {
        // width: '260px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    orderQtyEditWrapper: {

    },
    orderQtyEdtButton: {
        padding: '0px 0px 0px 10px',
    },
    actionIconBtn: {
        cursor: 'pointer',
        fontSize: '2.2rem'
    },
    orderDetailWrapperSm:{
        width: '100%',
        // paddingLeft: '100px'
    },
    orderDetailWrapperMd:{
        // width: '400px',
        // paddingLeft: '100px'
    },
    orderDetailWrapperAuto:{
        // paddingLeft: '100px'
    },
    normalOrderDetailWrapperSm: {
        // width: '270px',
    },
    normalOrderDetailWrapperMd: {
        // width: '450px',
    },
    normalOrderDetailWrapperAuto: {
    },
    orderDetailItem: {
        height: '30px',
    },
    orderPriceWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    detailOuterWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    selectedItem: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    simpleOptionsWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'space-between',
    },
    simpleOptions: {
        marginRight: '10px',
    }
}));

const OrderCartItemGrid = (props) => {
    const classes = useStyles();
    
    // const {
    //     productData
    // } = useSelector((store) => store.productsReducer);
    const dispatch = useDispatch();

    const getSelectedSpecClasses = (idx) => {
        if (props.selectedItemIdx.idx === props.idx && props.selectedItemIdx.specIdx === idx) {
            return classes.selectedItem;
        }
        return classes.notSelectedItem;
    }

    const getSelectedNormalClasses = () => {
        if (props.selectedItemIdx.idx === props.idx) {
            return classes.selectedItem;
        }
        return classes.notSelectedItem;
    }

    const getSimpleOptions = (simpleOptions) => {
        if(simpleOptions) {
            return (
                <div className={classes.simpleOptionsWrapper}>
                    {
                        simpleOptions.map((simpleOption, idx) => {

                            return (
                                <div key={`${simpleOptions}_${idx}`} className={classes.simpleOptions} >
                                    <Typography variant="subtitle2" color="primary">
                                        {simpleOption} 
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }
    const getSpecWrapperClassName = () => {
        if (props.width === 'sm') {
            return classes.orderDetailWrapperSm;
        } else if (props.width === 'md') {
            return classes.orderDetailWrapperMd;
        } else {
            return classes.orderDetailWrapperAuto;
        }
    }
    const getWrapperClassName = () => {
        if (props.width === 'sm') {
            return classes.normalOrderDetailWrapperSm;
        } else if (props.width === 'md') {
            return classes.normalOrderDetailWrapperMd;
        } else {
            return classes.normalOrderDetailWrapperAuto;
        }
    }

    if (props.orderDetail.hasMenuSpec === 'true'){
        return (
            <React.Fragment>
    
                    {
                        props.orderDetail.productSpec.map(
                            (productSpecItem, idx) => {
    
                                return (
                                    <div className={getSelectedSpecClasses(idx)} key={`${productSpecItem}_${idx}`} onClick={() => {props.handleSelectItem({idx: props.idx, specIdx: idx})}}>
                                        <div className={classes.orderItemContainer} key={`orderItem_${idx}`}>
                                            <div className={classes.orderHeaderWrapper}>
                                                <div key={`productSpec_title_${idx}`}>
                                                    <div className={classes.orderTitleWrapper}>
                                                        <div>
                                                            <Typography variant="h6" align="left">
                                                                <b>{`${props.orderDetail.name} $${props.orderDetail.price}`}</b>
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.detailOuterWrapper}>
                                                <div className={classes.orderQtyWrapper}>
                                                    <Typography variant="subtitle1" color="primary">
                                                        {productSpecItem.qty}
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="primary">
                                                        X
                                                    </Typography>
                                                    &nbsp;
                                                </div>
                                                <div className={getSpecWrapperClassName()}>
                                                    {
                                                        productSpecItem.spec.map(
                                                            (specItem, idx) => {
                                                                const specNameList = specItem.specOptions.map(
                                                                    (opt) => {
                                                                        if (opt.specPrice > 0) {
                                                                            return `${opt.specName} $${opt.specPrice}`
                                                                        }
                                                                        return opt.specName
                                                                    }
                                                                );
                                                                return (
                                                                    <Typography key={`${productSpecItem}_${idx}_specItem`} align="left" variant={"subtitle1"}>
                                                                        {`• ${specNameList.join(', ')}`}
                                                                    </Typography>
                                                                );
                                                            }
                                                        )
                                                    }
                                                    {
                                                        getSimpleOptions(props.orderDetail.simpleOptions)
                                                    }
                                                    <Divider variant="middle"/>
                                                    <div className={classes.orderPriceWrapper} key={`productSpec_price_${idx}`}>
                                                        <div>
                                                        </div>
                                                        <div>
                                                            <Typography variant={"h6"}>
                                                                {`$${productSpecItem.priceForOne}/份`}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            <Typography variant={"h6"}>
                                                                {`$${productSpecItem.priceForOne * productSpecItem.qty}`}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
            </React.Fragment>
        )
    } else {
        return (
            <div className={getSelectedNormalClasses()} onClick={() => {props.handleSelectItem({idx: props.idx, specIdx: null})}}>
                <div className={classes.orderItemContainer}>
                    <div className={classes.orderContiner}>
                        <div className={classes.orderHeaderWrapper}>
                            {/* <div className={classes.orderRemoveWrapper}>
                                <IconButton color="primary" onClick={()=> {props.updateCart(props.orderDetail, 'normal', 'remove')}}>
                                    <CancelIcon/>
                                </IconButton>
                            </div> */}
                            <div key={`order_title_${props.idx}`}>
                                <div className={classes.orderTitleWrapper}>
                                    <div>
                                        <Typography variant="h6" align="center">
                                            <b>{`${props.orderDetail.name} $${props.orderDetail.price}`}</b>
                                        </Typography>

                                    </div>
                                    {/* <div className={classes.orderQtyEditWrapper}>
                                        <IconButton className={classes.orderQtyEdtButton} onClick={()=> {props.updateCart(props.orderDetail, 'normal', 'add')}}>
                                        <AddCircleIcon/>
                                        </IconButton>
                                        <IconButton className={classes.orderQtyEdtButton} color="primary" onClick={()=> {props.updateCart(props.orderDetail, 'normal', 'del')}}>
                                        <RemoveCircleIcon/>
                                        </IconButton>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className={getWrapperClassName()}>
                            {
                                getSimpleOptions(props.orderDetail.simpleOptions)
                            }
                            <Divider variant="middle"/>
                            <div className={classes.orderPriceWrapper} key={`order_price_${props.idx}`}>
                                <div className={classes.orderQtyWrapper}>
                                    <Typography variant="subtitle1" color="primary">
                                        {props.orderDetail.qty}
                                    </Typography>
                                    &nbsp;
                                    <Typography variant={"subtitle1"} color="primary">
                                        X
                                    </Typography>
                                    &nbsp;
                                </div>
                                <div>
                                    <Typography variant={"h6"}>
                                        {`$${props.orderDetail.price}/份`}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant={"h6"}>
                                        {`$${props.orderDetail.price * props.orderDetail.qty}`}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

OrderCartItemGrid.propTypes = {
    width: PropTypes.string,
};

OrderCartItemGrid.defaultProps = {
    width: 'sm'
};

export default OrderCartItemGrid;