import * as ActionTypes from '../actions';
import lng from '../misc/lng';

const path = window.location.pathname;

const initialStateObj = {
    mode: '',
    view: 'landingPage',
    // view: 'addNoodles',
    appBarTitle: lng.chiLng.toolbarTitle,
    productId: '',
    generatedOrderId: null,
    orderTimeStamp: null,
    shop: 'sh01',
    ws: null, // websocket obj, for handling real-time system settings retrival and updating UI accordingly
};

if (path.split('order').length > 1 ) {
    const id = path.split('/order/')[1];
    initialStateObj.view = 'orderSummary';
    initialStateObj.productId = id;
}

if (path.split('admin').length > 1 ) {
    initialStateObj.view = 'admin';
    initialStateObj.mode = 'admin';
} else {
    initialStateObj.mode = 'dineIn';
}

const commonReducer = (state = initialStateObj, action) => {
    console.log(action.type);
    switch(action.type) {
        // Web socket object formed between cloud client and cloud server
        case ActionTypes.SAVE_LOCAL_WS_OBJ:
            return {
                ...state,
                ws: action.ws
            }
        // case ActionTypes.SAVE_WS_CLOUD_OBJ:
        //     return {
        //         ...state,
        //         ws_cloud: action.ws_cloud
        //     }
        case ActionTypes.UPDATE_APPBAR_TITLE:
            return {
                ...state,
                appBarTitle: action.title,
            }
        case ActionTypes.CHANGE_VIEW:
            return {
                ...state,
                view: action.data,
                generatedOrderId: action.generatedOrderId,
                orderTimeStamp: action.orderTimeStamp,
            };
        case ActionTypes.CHANGE_SHOP:
            return {
                ...state,
                shop: action.data,
            };
        default:
            return {
                ...state,
            }
    }
}

export default commonReducer;
