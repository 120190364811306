import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import LoadingWrapper from '../components/DineIn/LoadingWrapper';
import FailLoadingWrapper from '../components/DineIn/FailLoadingWrapper';
import DineInPage from '../components/DineIn/DineInPage';
import envConst from '../misc/envConst';
import { initDineInPage, initFail } from '../actions/dineIn';
import { initMenu } from '../actions/menu';
// import BigMenuCustCatWrapper from '../components_bk/DineIn/Menu/BigMenuCustCatWrapper';
// import AddSpecMealDialog from '../components_bk/bigMenu/AddSpecMealDialog';
// import CartBox from '../components_bk/DineIn/CartBox';
import lng from '../misc/lng';
import { ConsoleNetworkOutline } from 'mdi-material-ui';

const {chiLng} = lng;

export default () => {
    const dispatch = useDispatch();
    // const {
    //     initStatus
    // } = useSelector((store) => store.dineInReducer);
    const {
        loadingWrapper,
        failLoadingWrapper
    } = useSelector((store) => store.dineInReducer);
    useEffect(() => {
        console.log('ClientDineIn');

        // get Table runTimeHash from URL
        const searchParam = window.location.search;
        var params = new URLSearchParams(searchParam);
        const tableHash = params.get('table');

        console.log('tableHash');
        console.log(tableHash);
        if (tableHash) {
            const targetUrl = `${envConst.API_DINE_IN}`;
            axios.post(targetUrl,
                {
                    runtimeHash: tableHash
                }
            ).then(
                (response) => {
                    console.log('DineIn api call Response');
                    console.log(response);
                    if (response.data && response.data.success && response.data.tableInfo) {

                        const tableInfo = {...response.data.tableInfo};

                        const menuTargetUrl =  `${envConst.API_GET_ENTIRE_MENU}`;
                        axios.post(menuTargetUrl,
                            {
                                id: "sh01" 
                            }
                        ).then((response) => {
                            console.log('Entire Menu api call Response');
                            console.log(response);
                            if (response.data && response.data.success && response.data.result) {
                                
                                dispatch(initMenu(response.data.result));
                                dispatch(initDineInPage(tableInfo));

                            } else {
                                console.log('Get menu error');
                                dispatch(initFail(chiLng.failToLoadMenu));
                            }
                        });

                        
                    } else {
                        // get table information error
                        console.log('get table information error');
                        dispatch(initFail(chiLng.failToLoadTable));
                    }
                },
                (fail) => {
                    console.log('fail');
                    console.log(fail);
                    dispatch(initFail(chiLng.failToLoadTable))
                }
            )
        } else {
            dispatch(initFail(chiLng.failToLoadTable))
        }
        return () => {
            console.log('dineInPage close');
        }
    }, [dispatch]);

    const getPage = () => {
        if (failLoadingWrapper.status === 'completed') {
            return (
                <DineInPage />
            );
        } else if (failLoadingWrapper.status === 'initFail' || failLoadingWrapper.status === 'orderFail' ) {
            return (
                <FailLoadingWrapper title={failLoadingWrapper.title}/>
            );
        } else {
            return (<LoadingWrapper />);
        }
    }

    return (
        <div>
            {
                loadingWrapper.status === 'loading' || loadingWrapper.status === 'addOrderSuccess' || loadingWrapper.status === 'fail' ?
                (<LoadingWrapper status={loadingWrapper.status} title={loadingWrapper.title}/>) : (null)
            }
            {getPage()}
            {/* <AddSpecMealDialog /> */}
            {/* <CartBox /> */}
        </div>
    );
};