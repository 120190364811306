import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText, Typography } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import lng from '../../misc/lng';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const { chiLng } = lng;

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: theme.spacing(2),
    }
}));

export default function ContactInfoDialog(
    {
        open,
        handleClose,
        handleConfirm,
    }
) {
    const classes = useStyles();
    const [email, setEmail] = useState({
        value: '',
        error: false,
        helperText: ' ',
    });
    const [phone, setPhone] = useState({
        value: '',
        error: false,
        helperText: ' ',
    });

    const [isFormValid, setIsFormValid] = useState(false);

    const handleChange = (field, event) => {
        const value = event.target.value;
        let error = false;
        let helperText = ' ';
        switch (field) {
            case 'email':
                const emailValidation = /^\S+@\S+\.\S+$/;
                if (!emailValidation.test(value)) {
                    error = true;
                    helperText = 'Please enter a valid email';
                }
                setEmail(
                    {
                        value,
                        error,
                        helperText,
                    }
                );
                if (!error) {
                    setIsFormValid(true);
                  } else if (phone.value && !phone.error) {
                    setIsFormValid(true);
                  } else {
                    setIsFormValid(false);
                  }
                  break;
                break;
            case 'phone':
                const phoneValidation = /^[2-3|5-9]\d{7}$/;
                if (!phoneValidation.test(value)) {
                    error = true;
                    helperText = 'Please enter a valid HK phone number';
                }
                setPhone({
                    value,
                    error,
                    helperText,
                });
                if (!error) {
                    setIsFormValid(true);
                  } else if (email.value && !email.error) {
                    setIsFormValid(true);
                  } else {
                    setIsFormValid(false);
                  }
                break;
            default:
                break;
        }
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-slide-title">{chiLng.contactInfoDialogTitle}</DialogTitle>
            <DialogContent>

                <DialogContentText>
                    {chiLng.contactInfoDescription}
                </DialogContentText>

                <FormControl fullWidth className={classes.input}>
                    <InputLabel htmlFor="email">{chiLng.emailLabel}</InputLabel>
                    <Input
                        id="email"
                        value={email.value}
                        onChange={(e) => handleChange('email', e)}
                        label="email"
                    />
                    <FormHelperText id="email">{email.helperText}</FormHelperText>
                </FormControl>
                <Typography variant="body2" align='center'>
                    {chiLng.or}
                </Typography>
                <FormControl fullWidth>
                    <InputLabel htmlFor="phone">{chiLng.phoneNoLabel}</InputLabel>
                    <Input
                        id="phone"
                        value={phone.value}
                        onChange={(e) => handleChange('phone', e)}
                        label="phone"
                    />
                    <FormHelperText id="email">{phone.helperText}</FormHelperText>
                </FormControl>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {chiLng.cancel}
                    </Button>
                    <Button onClick={() => {handleConfirm(email, phone)}} color="primary" disabled={!isFormValid}>
                        {chiLng.confirm}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>

    );
}
