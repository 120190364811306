import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import lng from '../../misc/lng';
import moment from 'moment';
import OrderDetail from '../../MealClass/OrderDetail';
import { ConsoleNetwork } from 'mdi-material-ui';
import OnlineOrderGrid from './OnlineOrderGrid';
import LocalOrderGrid from './LocalOrderGrid';


const useStyles = makeStyles((theme) => {
    return {
        containerStyle: {
            overflowY: 'scroll',
            marginTop: '2em'
        },
        masonry: {
            marginTop: '1em',
            marginLeft: '1em',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: '1rem 3rem',
            gridTemplateRows: 'masonry',
        },
    }
})


const AllOrderMonitor = ({ paymentCompletedOrders, handleMonitorGridClick }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {/* <Grid container spacing={2} className={[classes.containerStyle, classes.root].join(' ')}> */}
            <div className={classes.masonry}>
                {paymentCompletedOrders.map((order, idx) => {
                    if (order.tableType === 'onlineOrder') {
                        return (<OnlineOrderGrid realTimeOrder={order} key={order._id} idx={idx} handleMonitorGridClick={handleMonitorGridClick} />);
                    }
                    return (<LocalOrderGrid realTimeOrder={order} key={order.id} idx={idx} handleMonitorGridClick={handleMonitorGridClick} />);
                })}
            </div>
            {/* </Grid> */}
        </React.Fragment>
    )
};


export default AllOrderMonitor;
